<div id="layout-wrapper">
    <app-topbar>
    </app-topbar>

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <!-- <div class="main-content">
        <div class="page-content">


         


            <div class="row">
                <div class="col-lg-10" style="margin: auto;">
                    <div class="card p-4">

                        <div class="text-left">
                            <h3 class="font-size-18 mt-4">Settings!</h3>
                        </div>


                        <ul ngbNav #justifiednav="ngbNav" [activeId]="1" class="nav-pills nav-justified">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">Datalake</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink>
                                    <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                                    <span class="d-none d-sm-block">CYBOT</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <ng-template [ngTemplateOutlet]="TabContent2"></ng-template>
                                </ng-template>
                            </li> -->

    <!-- <li [ngbNavItem]="3">
                                <a ngbNavLink>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">Datalake-Dev</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <ng-template [ngTemplateOutlet]="TabContent3"></ng-template>
                                </ng-template>
                            </li> -->
    <!-- <li [ngbNavItem]="4">
                                <a ngbNavLink>
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">Automation Exceptions</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <ng-template [ngTemplateOutlet]="TabContent4"></ng-template>
                                </ng-template>
                            </li>


                        </ul>
                        <div [ngbNavOutlet]="justifiednav"></div>
                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div> -->

    <div class="main-content">
        <div class="page-content">


            <!-- END layout-wrapper -->


            <div class="row">
                <div class="col-lg-10" style="margin: auto;">
                    <div class="card p-4">


                        <div class="row" style="min-height: 70vh;">
                            <div class="col-md-2">
                                <div class="text-left">
                                    <h3 class="font-size-18 mt-4">Settings!</h3>
                                </div>
                                <ul class="sidebar">
                                    <ng-container *ngFor="let tab of settings_tab.tabs;let i = index;">
                                        <li [class]="settings_tab.selectedIndex==i?'active':''"
                                            (click)="settings_tab.selectedIndex=i" *ngIf="tab?.show"><i
                                                [class]="tab.icon" aria-hidden="true"></i>{{tab.name}}</li>
                                    </ng-container>
                                </ul>
                            </div>
                            <i class="fa-regular fa-memo-circle-check"></i>
                            <div class="col-md-10">
                                <div class="card bg-card p-4">
                                    <h3 class="font-size-18">{{settings_tab.tabs[settings_tab.selectedIndex].name}}</h3>
                                    <div class="top-tab mt-2">
                                        <ul
                                            *ngIf="settings_tab.tabs[settings_tab.selectedIndex].showSingleTab || settings_tab.tabs[settings_tab.selectedIndex].sub_tab.length>1">
                                            <li [class]="settings_tab.tabs[settings_tab.selectedIndex].selectedIndex==i?'active':''"
                                                *ngFor="let tab of settings_tab.tabs[settings_tab.selectedIndex].sub_tab;let i = index;"
                                                (click)="settings_tab.tabs[settings_tab.selectedIndex].selectedIndex=i">
                                                <i [class]="tab.icon" aria-hidden="true"></i>{{tab.name}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-data">
                                        <div>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='TabContent1'">
                                                <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='TabContent2'">
                                                <ng-template [ngTemplateOutlet]="TabContent2"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='HealthStatus'">
                                                <ng-template [ngTemplateOutlet]="HealthStatus"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='TabContent4'">
                                                <ng-template [ngTemplateOutlet]="TabContent4"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='TabContent5'">
                                                <ng-template [ngTemplateOutlet]="TabContent5"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='integrationinvestigate'">
                                                <ng-template [ngTemplateOutlet]="integrationinvestigate"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='integrationueba'">
                                                <ng-template [ngTemplateOutlet]="integrationueba"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='notificationinvestigate'">
                                                <ng-template [ngTemplateOutlet]="notificationinvestigate"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='backupinvestigate'">
                                                <ng-template [ngTemplateOutlet]="backupinvestigate"></ng-template>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='siemintegrationinvestigate'">
                                                <ng-template
                                                    [ngTemplateOutlet]="siemintegrationinvestigate"></ng-template>
                                            </ng-container>
                                            <ng-container
                                            *ngIf="settings_tab.tabs[settings_tab.selectedIndex].sub_tab[settings_tab.tabs[settings_tab.selectedIndex].selectedIndex].tab=='timeZoneSettings'">
                                            <ng-template
                                                [ngTemplateOutlet]="timeZone"></ng-template>
                                        </ng-container>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>
<ng-template #TabContent1>
    <div class="p-2 mt-2">
        <form class="form-horizontal" [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="datalakeuname">Datalake name</label>
                <input formControlName="datalakeuname" class="form-control" id="datalakeuname"
                    placeholder="datalakeuname" [ngClass]="{ 'is-invalid': submitted && f.datalakeuname.errors }">
                <div *ngIf="submitted && f.datalakeuname.errors" class="invalid-feedback">
                    <div *ngIf="f.datalakeuname.errors.required">Datalake name is required</div>
                </div>
            </div>
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="datalakepass">Datalake password</label>
                <input type="password" formControlName="datalakepass" class="form-control" id="datalakepass"
                    placeholder="datalakepass" [ngClass]="{ 'is-invalid': submitted && f.datalakepass.errors }">
                <div *ngIf="submitted && f.datalakepass.errors" class="invalid-feedback">
                    <div *ngIf="f.datalakepass.errors.required">Datalake password is required</div>
                </div>
            </div>
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="datalakeurl">Datalake url</label>
                <input formControlName="datalakeurl" class="form-control" id="datalakeurl" placeholder="datalakeurl"
                    [ngClass]="{ 'is-invalid': submitted && f.datalakeurl.errors }">
                <div *ngIf="submitted && f.datalakeurl.errors" class="invalid-feedback">
                    <div *ngIf="f.datalakeurl.errors.required">Datalake url is required</div>
                </div>
            </div>
            <!-- <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-user-2-line auti-custom-input-icon"></i>
                                <div formArrayName="privusers">
                                    <div class="tbl">
                                        <div class="tblRow siNo">
                                            <div class="tblCells btn">
                                                <div type="button" (click)="addUser()">Add Priv Users&nbsp;&#43;</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tbl_outer" *ngFor="let user of settingsForm.get('privusers')['controls']; let i=index">
                                        <div class="tbl">
                                            <div class="tblRow" [formGroupName]="i">
                                                <div class="tblCells siNo">Priv User : {{i+1}}</div>
                                                <div class="tblCells"><input class="form-control user" type="text"
                                                        formControlName="user"></div>
                                                <div class="tblCells btn"><span (click)="removeUser(i)">Remove&nbsp;&#45;</span>
                                                </div>
                                            </div>
                                        </div>
            
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.privusers.errors" class="invalid-feedback">
                                    <div *ngIf="f.privusers.errors.required">Datalake url is required</div>
                                </div>
                            </div> -->
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <div formArrayName="variables">
                    <div class="tbl">
                        <div class="tblRow">
                            <div class="tblCells btn">
                                <div type="button" (click)="addVariables()">Add Variables&nbsp;&#43;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tbl_outer" *ngFor="let user of settingsForm.get('variables')['controls']; let i=index">
                        <div class="tbl">
                            <div class="tblRow" [formGroupName]="i">
                                <div class="tblCells">
                                    <input class="form-control user" readonly *ngIf="!user.controls.new.value"
                                        type="text" formControlName="name">
                                    <input class="form-control user" *ngIf="user.controls.new.value" type="text"
                                        formControlName="name">
                                </div>
                                <div class="tblCells">
                                    <input class="form-control user" type="text" formControlName="value">
                                </div>
                                <div class="tblCells btn"><span (click)="removeVariables(i)">Remove&nbsp;&#45;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="submitted && f.variables.errors" class="invalid-feedback">
                    <div *ngIf="f.variables.errors.required">Variables url is required</div>
                </div>
            </div>




            <div class="mt-4 text-left" *ngIf="!isSaving&&!isSuccess">
                <button style="margin-right:15px" class="btn btn-primary w-md waves-effect waves-light"
                    (click)="backHome()" type="button">Back</button>
                <button *ngIf="adminstatus" class="btn btn-primary w-md waves-effect waves-light" type="submit" [ngClass]="{
                                                    'disabled-action': submitted                     
                                                    }" [disabled]="!settingsForm.valid">Save</button>

            </div>
            <div class="mt-4 text-left cybot_loader">
                <div *ngIf="isSaving">
                    Saving ...
                </div>
                <div *ngIf="isSuccess">
                    Saved Successfully
                </div>

            </div>
        </form>
    </div>
</ng-template>
<ng-template #TabContent2>
    <div class="p-2 mt-2">
        <form class="form-horizontal" [formGroup]="cybotForm" (ngSubmit)="onSubmit1()">
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="cybotuname">CYBOT name</label>
                <input formControlName="cybotuname" class="form-control" id="cybotuname" placeholder="cybotuname"
                    [ngClass]="{ 'is-invalid': submitted1 && f1.cybotuname.errors }">
                <div *ngIf="submitted1 && f1.cybotuname.errors" class="invalid-feedback">
                    <div *ngIf="f1.cybotuname.errors.required">CYBOT name is required</div>
                </div>
            </div>
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="cybotpass">CYBOT password</label>
                <input type="password" formControlName="cybotpass" class="form-control" id="cybotpass"
                    placeholder="cybotpass" [ngClass]="{ 'is-invalid': submitted1 && f1.cybotpass.errors }">
                <div *ngIf="submitted1 && f1.cybotpass.errors" class="invalid-feedback">
                    <div *ngIf="f1.cybotpass.errors.required">CYBOT password is required</div>
                </div>
            </div>
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="cyboturl">CYBOT url</label>
                <input formControlName="cyboturl" class="form-control" id="cyboturl" placeholder="cyboturl"
                    [ngClass]="{ 'is-invalid': submitted1 && f1.cyboturl.errors }">
                <div *ngIf="submitted1 && f1.cyboturl.errors" class="invalid-feedback">
                    <div *ngIf="f1.cyboturl.errors.required">CYBOT url is required</div>
                </div>
            </div>


            <div class="mt-4 text-left" *ngIf="!isSaving&&!isSuccess">
                <button style="margin-right:15px" class="btn btn-primary w-md waves-effect waves-light"
                    (click)="backHome()" type="button">Back</button>
                <button *ngIf="adminstatus" class="btn btn-primary w-md waves-effect waves-light" type="submit" [ngClass]="{
                                                    'disabled-action': submitted1                     
                                                    }" [disabled]="!cybotForm.valid">Save</button>

            </div>
            <div class="mt-4 text-left cybot_loader">
                <div *ngIf="isSaving">
                    Saving ...
                </div>
                <div *ngIf="isSuccess">
                    Saved Successfully
                </div>

            </div>
        </form>
    </div>
</ng-template>


<ng-template #TabContent3>
    <div class="p-2 mt-2">
        <form class="form-horizontal" [formGroup]="settingsForm2" (ngSubmit)="onSubmit2()">
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="datalakeuname_dev">Datalake dev name</label>
                <input formControlName="datalakeuname_dev" class="form-control" id="datalakeuname_dev"
                    placeholder="datalakeuname_dev"
                    [ngClass]="{ 'is-invalid': submitted && f2.datalakeuname_dev.errors }">
                <div *ngIf="submitted && f2.datalakeuname_dev.errors" class="invalid-feedback">
                    <div *ngIf="f2.datalakeuname_dev.errors.required">Datalake dev name is required</div>
                </div>
            </div>
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="datalakepass_dev">Datalake dev password</label>
                <input type="password" formControlName="datalakepass_dev" class="form-control" id="datalakepass_dev"
                    placeholder="datalakepass_dev"
                    [ngClass]="{ 'is-invalid': submitted && f2.datalakepass_dev.errors }">
                <div *ngIf="submitted && f2.datalakepass_dev.errors" class="invalid-feedback">
                    <div *ngIf="f2.datalakepass_dev.errors.required">Datalake dev password is required</div>
                </div>
            </div>
            <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-2-line auti-custom-input-icon"></i>
                <label for="datalakeurl_dev">Datalake dev url</label>
                <input formControlName="datalakeurl_dev" class="form-control" id="datalakeurl_dev"
                    placeholder="datalakeurl_dev" [ngClass]="{ 'is-invalid': submitted && f2.datalakeurl_dev.errors }">
                <div *ngIf="submitted && f2.datalakeurl_dev.errors" class="invalid-feedback">
                    <div *ngIf="f2.datalakeurl_dev.errors.required">Datalake dev url is required</div>
                </div>
            </div>

            <div class="mt-4 text-left" *ngIf="!isSaving&&!isSuccess">
                <button style="margin-right:15px" class="btn btn-primary w-md waves-effect waves-light"
                    (click)="backHome()" type="button">Back</button>
                <button class="btn btn-primary w-md waves-effect waves-light" type="submit" [ngClass]="{
                                                    'disabled-action': submitted                     
                                                    }" [disabled]="!settingsForm2.valid">Save</button>

            </div>
            <div class="mt-4 text-left cybot_loader">
                <div *ngIf="isSaving">
                    Saving ...
                </div>
                <div *ngIf="isSuccess">
                    Saved Successfully
                </div>

            </div>
        </form>
    </div>
</ng-template>
<ng-template #TabContent4>
    <div class="p-2 mt-2">
        <app-automation-exceptions type="Investigate"></app-automation-exceptions>
    </div>
</ng-template>
<ng-template #TabContent5>
    <div class="p-2 mt-2">
        <app-automation-exceptions type="UEBA"></app-automation-exceptions>
    </div>
</ng-template>
<ng-template #HealthStatus>
    <div class="p-2 mt-2">
        <div class="sections">
            <h4>Datalake Health Status</h4>
            <ul>
                <li><span>active_shards_percent: </span>{{DatalikeHealthData?.active_shards_percent}}</li>
                <li><span>cluster: </span>{{DatalikeHealthData?.cluster}}</li>
                <li><span>epoch: </span>{{DatalikeHealthData?.epoch}}</li>
                <li><span>init: </span>{{DatalikeHealthData?.init}}</li>
                <li><span>max_task_wait_time: </span>{{DatalikeHealthData?.max_task_wait_time}}</li>
                <li><span>node data: </span>{{dataCheck(DatalikeHealthData,'data')}}</li>
                <li><span>node total: </span>{{dataCheck(DatalikeHealthData,'total')}}</li>
                <li><span>pending_tasks: </span>{{DatalikeHealthData?.pending_tasks}}</li>
                <li><span>pri: </span>{{DatalikeHealthData?.pri}}</li>
                <li><span>relo: </span>{{DatalikeHealthData?.relo}}</li>
                <li><span>shards: </span>{{DatalikeHealthData?.shards}}</li>
                <li><span>status: </span>{{DatalikeHealthData?.status}}</li>
                <li><span>timestamp: </span>{{DatalikeHealthData?.timestamp}}</li>
                <li><span>unassign: </span>{{DatalikeHealthData?.unassign}}</li>
            </ul>
        </div>
        <br>
        <div class="sections mt-4">
            <h4>CYBOT Health Status </h4>
            <ul>
                <li><span>active_shards_percent: </span>{{CYBOTHealthData?.active_shards_percent}}</li>
                <li><span>cluster: </span>{{CYBOTHealthData?.cluster}}</li>
                <li><span>epoch: </span>{{CYBOTHealthData?.epoch}}</li>
                <li><span>init: </span>{{CYBOTHealthData?.init}}</li>
                <li><span>max_task_wait_time: </span>{{CYBOTHealthData?.max_task_wait_time}}</li>
                <li><span>node data: </span>{{dataCheck(DatalikeHealthData,'data')}}</li>
                <li><span>node total: </span>{{dataCheck(DatalikeHealthData,'total')}}</li>
                <li><span>pending_tasks: </span>{{CYBOTHealthData?.pending_tasks}}</li>
                <li><span>pri: </span>{{CYBOTHealthData?.pri}}</li>
                <li><span>relo: </span>{{CYBOTHealthData?.relo}}</li>
                <li><span>shards: </span>{{CYBOTHealthData?.shards}}</li>
                <li><span>status: </span>{{CYBOTHealthData?.status}}</li>
                <li><span>timestamp: </span>{{CYBOTHealthData?.timestamp}}</li>
                <li><span>unassign: </span>{{CYBOTHealthData?.unassign}}</li>

            </ul>
        </div>

    </div>
</ng-template>
<ng-template #integrationinvestigate>
    <div class="p-2 mt-2">
        <app-list-integrations type="Investigate"></app-list-integrations>
    </div>
</ng-template>
<ng-template #integrationueba>
    <div class="p-2 mt-2">
        <app-list-integrations type="UEBA"></app-list-integrations>
    </div>
</ng-template>
<ng-template #notificationinvestigate>
    <div class="p-2 mt-2">
        <p style="margin-top: 20px;">
            This page is for updating the settings of notification emails that are being
            sent.
            Please enter or update the email ID to which the notification emails are to be
            sent.
        </p>
        <div class="row w-100">
            <div class="col-lg-12">
                <form [formGroup]="notificationsForm" (ngSubmit)="updateNotifications()" role="form">
                    <div class="col-md-12">
                        <div class="row">

                            <div class="mb-4 col-md-6">
                                <div class="custom-control custom-checkbox custom-control-left">
                                    <input formControlName="threat_hunting_report" type="checkbox"
                                        (change)="handleCheckChange($event,'threat_hunting_report')"
                                        class="custom-control-input position-relative" id="threat_hunting_report">
                                    <label class="custom-control-label" for="threat_hunting_report">Daily Threat Hunting
                                        Report </label>
                                    <input [ngClass]="{
'disabled-action': threat_hunting_report_value=='yes'?false:true                    
}" [disabled]="threat_hunting_report_value=='yes'?true:false" formControlName="threat_hunting_report_email"
                                        class="form-control f-cb-align" type="email" placeholder="Default input"
                                        [attr.required]="notificationsForm.value.threat_hunting_report">
                                    <div class="error-div">
                                        <small class="text-danger"
                                            *ngIf="notificationsForm.controls.threat_hunting_report_email.errors?.emails">
                                            Must be Email
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="notificationsForm.controls.threat_hunting_report_email.errors?.required && threat_hunting_report_value=='yes'">
                                            Email Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4 col-md-6">
                                <div class="custom-control custom-checkbox custom-control-left">
                                    <input formControlName="threat_intelligence_individual" type="checkbox"
                                        (change)="handleCheckChange($event,'threat_intelligence_individual')"
                                        class="custom-control-input position-relative"
                                        id="threat_intelligence_individual">
                                    <label class="custom-control-label" for="threat_intelligence_individual">Threat
                                        intelligence daily Email </label>
                                    <input [ngClass]="{
'disabled-action': threat_intelligence_individual_value=='yes'?false:true                  
}" [disabled]="threat_intelligence_individual_value=='yes'?true:false"
                                        formControlName="threat_intelligence_daily_email"
                                        class="form-control f-cb-align" type="text" placeholder="Default input">
                                    <div class="error-div">
                                        <small class="text-danger"
                                            *ngIf="notificationsForm.controls.threat_intelligence_daily_email.errors?.emails">
                                            Must be Email
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="notificationsForm.controls.threat_intelligence_daily_email.errors?.required && threat_intelligence_individual_value=='yes'">
                                            Email Required
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-4 col-md-6">
                                <div class="custom-control custom-checkbox custom-control-left">
                                    <input formControlName="threat_intelligence_weekly" type="checkbox"
                                        (change)="handleCheckChange($event,'threat_intelligence_weekly')"
                                        class="custom-control-input position-relative" id="threat_intelligence_weekly">
                                    <label class="custom-control-label" for="threat_intelligence_weekly">Threat
                                        intelligence
                                        weekly Email </label>
                                    <input [ngClass]="{
'disabled-action': threat_intelligence_weekly_value=='yes'?false:true                     
}" [disabled]="threat_intelligence_weekly_value=='yes'?true:false" formControlName="threat_intelligence_weekly_email"
                                        class="form-control f-cb-align" type="text" placeholder="Default input">
                                    <div class="error-div">
                                        <small class="text-danger"
                                            *ngIf="notificationsForm.controls.threat_intelligence_weekly_email.errors?.emails">
                                            Must be Email
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="notificationsForm.controls.threat_intelligence_weekly_email.errors?.required && threat_intelligence_weekly_value=='yes'">
                                            Email Required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4 col-md-6">
                                <div class="custom-control custom-checkbox custom-control-left">
                                    <input formControlName="threat_intelligence_monthly" type="checkbox"
                                        (change)="handleCheckChange($event,'threat_intelligence_monthly')"
                                        class="custom-control-input position-relative" id="threat_intelligence_monthly">
                                    <label class="custom-control-label" for="threat_intelligence_monthly">Threat
                                        intelligence monthly Email </label>
                                    <input [ngClass]="{
'disabled-action': threat_intelligence_monthly_value=='yes'?false:true                 
}" [disabled]="threat_intelligence_monthly_value=='yes'?true:false" formControlName="threat_intelligence_monthly_email"
                                        class="form-control f-cb-align" type="text" placeholder="Default input">
                                    <div class="error-div">
                                        <small class="text-danger"
                                            *ngIf="notificationsForm.controls.threat_intelligence_monthly_email.errors?.emails">
                                            Must be Email
                                        </small>
                                        <small class="text-danger"
                                            *ngIf="notificationsForm.controls.threat_intelligence_monthly_email.errors?.required && threat_intelligence_monthly_value=='yes'">
                                            Email Required
                                        </small>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12 mb-2 text-right">
                                <button type="submit" class="btn" [disabled]="isLoading || 
(notificationsForm.value.threat_intelligence_monthly && notificationsForm.controls.threat_intelligence_monthly_email.errors) || 
(notificationsForm.value.threat_intelligence_weekly && notificationsForm.controls.threat_intelligence_weekly_email.errors) ||
(notificationsForm.value.threat_intelligence_individual && notificationsForm.controls.threat_intelligence_daily_email.errors) ||
(notificationsForm.value.threat_hunting_report && notificationsForm.controls.threat_hunting_report_email.errors)"><i
                                        class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-pencil-square-o': !isLoading}"></i>
                                    Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</ng-template>


<ng-template #backupinvestigate>
    <div class="p-2 mt-2">
        This page shows the configuration related to backups and restore. Use the frequency
        tab to set up how often backups
        should be taken and their retention period. Use the restore tab to view the existing
        backups in the backup repository,
        and delete/restore existing backups in the repository.

        <div>
            <div class="btnBackups mb-2">
                <button class="sw-left" [class]="backupStatus?'sw-selected':''"
                    (click)="backupClick()">Frequency</button>
                <button class="sw-right" [class]="!backupStatus?'sw-selected':''"
                    (click)="getAllSnapshots()">Restore</button>
            </div>

            <div *ngIf="backupStatus" class="backupBox">
                <div class="row m-0">
                    <div class="col-md-4 my-1">
                        <label class="mr-sm-2">Frequency</label>
                        <select class="custom-select mr-sm-2" (change)="frequencyChange($event)">
                            <option selected value="hour">Hour</option>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                        </select>
                    </div>
                    <div class="col-md-4 my-1">
                        <div class="form-group">
                            <label for="inputEmail4">Retention (In Days)</label>
                            <input type="number" min="0" class="form-control" (change)="retentionChange($event)"
                                placeholder="Retention (In Days)">
                            <small *ngIf="retentionDataError">
                                Retention is required
                            </small>
                            <small *ngIf="retentionNumError">
                                Retention must be greater than 0.
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4 my-1">
                        <div class="form-group">
                            <label for="inputEmail4">Number to store</label>
                            <input type="number" class="form-control" id="inputEmail4" placeholder="Number to store"
                                (change)="numbertostoreChange($event)">
                            <small *ngIf="numbertostoreDataError">
                                Number to store is required
                            </small>
                            <small *ngIf="numbertostoreNumError">
                                Number to store must be greater than 0.
                            </small>

                        </div>
                    </div>
                </div>
                <div class="col-auto mt-2" *ngIf="frequencyData=='year'">
                    <label class="mr-sm-2">Month</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">In</div>
                        </div>
                        <select class="custom-select mr-sm-2" [(ngModel)]="crone.month">
                            <option selected>Choose...</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </div>
                </div>
                <div class="col-auto mt-2" *ngIf="frequencyData=='week'">
                    <label class="mr-sm-2">Weekday</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">On</div>
                        </div>
                        <select class="custom-select mr-sm-2 " id="inlineFormCustomSelect" [(ngModel)]="crone.week">
                            <option selected>Choose...</option>
                            <option value="0">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuedsday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                        </select>
                    </div>
                </div>
                <div class="col" *ngIf="frequencyData=='year' || frequencyData=='month'">
                    <label class="mr-sm-2">Day</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">On the</div>
                        </div>
                        <select class="custom-select mr-sm-2 " id="inlineFormCustomSelect" [(ngModel)]="crone.day">
                            <option selected>Choose...</option>
                            <option *ngFor="let item of [].constructor(31);let i=index;">
                                {{i+1}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-auto">
                    <label class="mr-sm-2 ">Time</label>
                    <div class="form-row">
                        <div class="col"
                            *ngIf="frequencyData=='year' || frequencyData=='month' || frequencyData=='week' || frequencyData=='day'">
                            <div class="input-group mb-2 ">
                                <div class="input-group-prepend ">
                                    <div class="input-group-text ">At</div>
                                </div>
                                <select class="custom-select mr-sm-2 " id="inlineFormCustomSelect "
                                    [(ngModel)]="crone.hour">
                                    <option selected>Choose...</option>
                                    <option *ngFor="let item of [].constructor(23);let i=index;">
                                        {{i}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col"
                            *ngIf="frequencyData=='year' || frequencyData=='month' || frequencyData=='week' || frequencyData=='day' || frequencyData=='hour'">
                            <div class="input-group mb-2 ">
                                <div class="input-group-prepend ">
                                    <div class="input-group-text ">:</div>
                                </div>
                                <select class="custom-select mr-sm-2 " id="inlineFormCustomSelect "
                                    [(ngModel)]="crone.minute">
                                    <option selected>Choose...</option>
                                    <option *ngFor="let item of [].constructor(59);let i=index;">
                                        {{i}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 text-right">
                    <p class="text-success text-center mt-2 mb-1" *ngIf="isCroneCreated">
                        Crone
                        Created Successfully!</p>
                    <button class="btn btn-sm btn-primary mt-3 mr-4" (click)="submitCrone(frequencyData,crone)"><i
                            class="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2 text-danger"
                            *ngIf="isSubmittingCrone"></i> Submit</button>
                </div>

            </div>
            <div *ngIf="!backupStatus " class="backupBox">


                <table class="table restoreTable">
                    <thead>
                        <tr>
                            <th class="text-left">Snapshot</th>
                            <th class="text-left "><span class="">Start Time</span></th>
                            <th class="text-left "><span class="">End Time</span></th>
                            <th class="text-left "><span class="">Action</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [ngClass]="{ dis_on: isFetchingSnaps, dis_off: !isFetchingSnaps } ">
                            <img src="assets/images/fileloading.gif " width="100" />
                        </tr>
                        <tr *ngFor="let snaps of snapshotList">
                            <td class="text-left">{{snaps.name}} </td>
                            <td class="text-left shop-name">{{snaps.start_time |
                                date:'medium'}}
                            </td>
                            <td class="text-left shop-name">{{snaps.end_time |
                                date:'medium'}}</td>

                            <td class="text-left">
                                <a href="javascript:void(0); " (click)="openSnapModal(deleteSnapModal, snaps) "
                                    class="mr-1 text-danger " ngbTooltip="Delete "><i
                                        class="mdi mdi-delete font-size-18 "></i></a>
                                <a href="javascript:void(0); " class="text-info " ngbTooltip="restore "
                                    (click)="openSnapModal(restoreSnapModal, snaps)"><i
                                        class="mdi mdi-undo-variant font-size-18 "></i></a>
                            </td>
                        </tr>
                        <tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #siemintegrationinvestigate>
    <div class="p-2 mt-2">
        <p style="margin-top: 20px;">
            To integrate CYBOT with your SIEM, enter the details of your SIEM's syslog
            collector endpoint. Cybot
            will send the details of each detection in real time to the specified IP and
            port in Common event
            format.
        </p>
        <div class="row w-100">
            <div class="col-lg-12">
                <!-- <h4 class="card-title">Title</h4> -->
                <!-- <p class="card-title-desc">Some Title</p> -->
                <div class="mb-2">
                    <div class="custom-control custom-checkbox custom-control-left">
                        <input type="checkbox" class="custom-control-input" (click)="enableSyslogClick($event)"
                            id="customCheck4">
                        <label class="custom-control-label" for="customCheck4">Enable
                            Syslog</label>
                    </div>
                </div>
                <form [formGroup]="siemForm" role="form">
                    <div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label>Syslog IP</label>
                                <input formControlName="syslog_ip" [disabled]="isDisabledSiem" class="form-control"
                                    type="text" placeholder="Default input">
                                <small class="text-danger" *ngIf="siemForm.controls.syslog_ip.errors?.required
">
                                    IP is required
                                </small>
                                <small class="text-danger" *ngIf="siemForm.controls.syslog_ip.errors?.emails">
                                    Not an IP
                                </small>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label>Syslog Port</label>
                                <input formControlName="syslog_port" [disabled]="isDisabledSiem" class="form-control"
                                    type="number" placeholder="Default input">
                                <small class="text-danger" *ngIf="siemForm.controls.syslog_port.errors?.min">
                                    Min value is 0
                                </small>
                                <small class="text-danger" *ngIf="siemForm.controls.syslog_port.errors?.max">
                                    Max value is 6000
                                </small>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label>Protocol</label>
                                <select formControlName="protocol" [disabled]="isDisabledSiem" class="form-control">
                                    <option selected value="">Choose Protocol...</option>
                                    <option value="tcp">TCP</option>
                                    <option value="udp">UDP</option>
                                </select>
                            </div>
                            <div class="col-md-12 mb-2 text-right">
                                <button
                                    [disabled]="(isDisabledSiem && (this.tenantID != undefined || this.tenantID != ''))"
                                    (click)="updateSiem()" type="submit" class="btn btn-success">Update</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <ng-template #viewModal let-modal>
            <div class="modal-header">
                <h5 class="modal-title">{{Details}}</h5>
                <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click ')">
                    ×
                </button>
            </div>
            <div class="modal-body p-3 pb-4">
                <div class="row" *ngIf="modalId == 4">
                    <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
                    <div id="contentToConvert3">
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Comment : <br /><b>{{ selectedContent.comment }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Created At : <br /><b>{{ selectedContent.created_at }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Created By: <br /><b>{{ selectedContent.created_by }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Host Name: <br /><b>{{ selectedContent.host_name }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Playbook Id: <br /><b>{{ selectedContent.playbook_id }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Playbook Name: <br /><b>{{ selectedContent.playbook_name }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Source Ip: <br /><b>{{ selectedContent.source_ip }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                User Name: <br /><b>{{ selectedContent.user_name }}</b>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="modalId == 5">
                    <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
                    <div id="contentToConvert3">
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Api Name : <b>{{ selectedContent.api_name }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Method : <b>{{ selectedContent.method }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                header: <b>{{ selectedContent.header }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                body: <b>{{ selectedContent.body }}</b>
                            </p>
                        </div>
                        <div class="col-md-12">
                            <p class="card-title-desc">
                                Url: <b>{{ selectedContent.url }}</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #timeZone>
    <div class="col-auto mt-2" >
        <label class="mr-sm-2">Date Format</label>
        <div class="input-group mb-2">
            <select class="custom-select mr-sm-2" [(ngModel)]="dateFormate">
                <option *ngFor="let item of dateFormatArray;let i = index;" value="{{i}}">{{item}}</option>    
            </select>
        </div>
    </div>
    <div class="col-auto mt-2" >
        <label class="mr-sm-2">Default TimePeriod</label>
        <div class="input-group mb-2">
            <select class="custom-select mr-sm-2" [(ngModel)]="timePeriod">
                <option *ngFor="let item of timePeriods;let i = index;" value="{{i}}">{{item}}</option>
            </select>
        </div>
    </div>
    <div class="col-auto mt-2" >
        <label class="mr-sm-2">Default TimeZone</label>
        <div class="input-group mb-2">
            <select class="custom-select mr-sm-2" [(ngModel)]="timeZoneSettings">
                <option value="utc">UTC TimeZone</option>
                    <option value="normal">Normal</option>
            </select>
        </div>
    </div>
    <div class="col-md-12 mb-2 text-right">
         <button  type="submit" class="btn btn-success" (click)="onDateFormatChange()">Update</button>
        </div>
</ng-template>


















<ng-template #viewModalSoar let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Details</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click ')">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pb-4">
        <div class="row" *ngIf="modalId == 4">
            <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
            <div id="contentToConvert3">
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Comment : <br /><b>{{ selectedContent.comment }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Created At : <br /><b>{{ selectedContent.created_at }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Created By: <br /><b>{{ selectedContent.created_by }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Host Name: <br /><b>{{ selectedContent.host_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Playbook Id: <br /><b>{{ selectedContent.playbook_id }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Playbook Name: <br /><b>{{ selectedContent.playbook_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Source Ip: <br /><b>{{ selectedContent.source_ip }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        User Name: <br /><b>{{ selectedContent.user_name }}</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteSnapModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-center">Delete</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click ')">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pb-4">
        <div class="row">
            <div class="text-center">Are you sure to delete</div>
            <div class="text-center"><b>{{deleteSnap?.name}}</b> ?</div>
            <p *ngIf="isSnapDeleting" class="mt-2 mb-0 text-center"><i
                    class="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2 text-danger"></i> Deleting</p>
        </div>
    </div>
    <div class="modal-footer">
        <div class="actions">
            <button class="btn btn-sm btn-danger mr-2" (click)="confirmSnapDelete(deleteSnap.name)">Delete</button>
            <button class="btn btn-sm btn-secondary" (click)="modal.dismiss('Cross click ')">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #restoreSnapModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-center">Restore</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click ')">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pb-4">
        <div class="row">
            <div class="text-center">Are you sure to restore</div>
            <div class="text-center"><b>{{deleteSnap?.name}}</b> ?</div>
            <p *ngIf="isSnapDeleting" class="mt-2 mb-0 text-center"><i
                    class="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2 text-danger"></i> Restoring</p>
        </div>
    </div>
    <div class="modal-footer">
        <div class="actions">
            <button class="btn btn-sm btn-danger mr-2" (click)="confirmSnapRestore(deleteSnap.name)">Restore</button>
            <button class="btn btn-sm btn-secondary" (click)="modal.dismiss('Cross click ')">Cancel</button>
        </div>
    </div>
</ng-template>


<ng-template #descriptionModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-center">Playbook</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click ')">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pb-4">
        <div class="row">
            <div class="text-center mb-2">ID: <b>{{scheduleDetails?.playbook_id}}</b></div>
            <div class="text-center mb-2">Name: <b>{{scheduleDetails?.playbook_name}}</b></div>
            <div class="text-center">{{scheduleDetails?.description}}</div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="actions">
            <button class="btn btn-sm btn-secondary" (click)="modal.dismiss('Cross click ')">Ok</button>
        </div>
    </div>
</ng-template>

<ng-template #viewModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{Details}}</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click ')">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pb-4">
        <div class="row" *ngIf="modalId == 4">
            <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
            <div id="contentToConvert3">
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Comment : <br /><b>{{ selectedContent.comment }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Created At : <br /><b>{{ selectedContent.created_at }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Created By: <br /><b>{{ selectedContent.created_by }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Host Name: <br /><b>{{ selectedContent.host_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Playbook Id: <br /><b>{{ selectedContent.playbook_id }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Playbook Name: <br /><b>{{ selectedContent.playbook_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Source Ip: <br /><b>{{ selectedContent.source_ip }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        User Name: <br /><b>{{ selectedContent.user_name }}</b>
                    </p>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="modalId == 5">
            <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
            <div id="contentToConvert3">
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Api Name : <b>{{ selectedContent.api_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Method : <b>{{ selectedContent.method }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        header: <b>{{ selectedContent.header }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        body: <b>{{ selectedContent.body }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Url: <b>{{ selectedContent.url }}</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>