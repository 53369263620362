import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { iif } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { SsoService } from "src/app/core/services/sso.service";
import { RuleStudioService } from "../../../core/services/rule-studio.service"
@Component({
  selector: 'app-investigation',
  templateUrl: './investigation.component.html',
  styleUrls: ['./investigation.component.scss']
})
export class InvestigationComponent implements OnInit {
  masterCatalogue: InvestigateCatalog[] = []
  masterCatalogueBackup: InvestigateCatalog[] = []
  tenantCatalogue: InvestigateCatalog[] = [];
  tenantCatalogueBackup: InvestigateCatalog[] = [];
  isLoaderShow: boolean = false;
  isOutputShow: boolean = false;
  isInputShow: boolean = false;
  editFlag: boolean = false;
  masterSearch: string;
  OrganizationSearch: string;

  constructor(private ruleStudioService: RuleStudioService, private router: Router, private ssoService: SsoService) { }

  ngOnInit(): void {
    this.getuserprivsData()
    this.getData();
  }

  getData() {
    this.isLoaderShow = true;
    this.tenantCatalogue = [];
    this.masterCatalogue = [];
    this.ruleStudioService.getCatalogueMaster()
      .pipe(
        switchMap((res: any) => {
          res.forEach(data => {
            let catalogue = new InvestigateCatalog();
            catalogue.data = data;
            if (this.tenantCatalogue.find(v => v.data[0] == data[0])) {
              catalogue.isDisabled = true;
            }
            this.masterCatalogue.push(catalogue);
            this.masterCatalogueBackup.push(catalogue);
          });
          return this.ruleStudioService.getCatalogueTenant()
        })
      ).subscribe((res: any) => {
        if (typeof res != "string") {
          res.forEach(data => {
            let tenant = new InvestigateCatalog();
            tenant.data = data;
            this.tenantCatalogue.push(tenant);
            this.tenantCatalogueBackup.push(tenant)
          });
        }
        this.isLoaderShow = false;
      })
  }

  onAdd(inputCatalogue) {
    if (!inputCatalogue.isDisabled) {
      this.isInputShow = true;
      this.ruleStudioService.AddAlerttoCollection(inputCatalogue.data[0])
        .subscribe((_) => {
          this.isInputShow = false;
          this.getData();
        }, err => this.isInputShow = false
        )
    }
  }

  onRemove(outPutCatalogue) {
    if (!outPutCatalogue.isDisabled) {
      this.isOutputShow = true;
      this.ruleStudioService.removeAlertCollection(outPutCatalogue.data[0]).subscribe((_) => {
        this.isOutputShow = false;
        this.getData();
      }, err => this.isOutputShow = false
      )
    }
  }
  onRefresh() {
    this.isLoaderShow = true;
    this.ruleStudioService.getRefreshTenant().pipe(
      switchMap(_ => {
        return this.ruleStudioService.getRefreshMaster()
      })
    ).subscribe((_) => {
      this.getData();
    })
  }
  gotoCustom() {
    this.router.navigateByUrl('rule-studio/custom');
  }
  onEdit(outPutCatalogue) {
    this.router.navigateByUrl('rule-studio/custom-edit/' + outPutCatalogue.data[0]);
  }

  searchMasterCatalog() {
    this.masterCatalogue = this.masterCatalogueBackup.filter(v => v.data[0].toLowerCase().indexOf(this.masterSearch.toLowerCase()) > -1 ||
      v.data[1].toLowerCase().indexOf(this.masterSearch.toLowerCase()) > -1 ||
      v.data[2].toLowerCase().indexOf(this.masterSearch.toLowerCase()) > -1)
  }

  searchOrganizationCatalog() {
    this.tenantCatalogue = this.tenantCatalogueBackup.filter(v => v.data[0].toLowerCase().indexOf(this.OrganizationSearch.toLowerCase()) > -1 ||
      v.data[1].toLowerCase().indexOf(this.OrganizationSearch.toLowerCase()) > -1 ||
      v.data[2].toLowerCase().indexOf(this.OrganizationSearch.toLowerCase()) > -1)
  }
  getuserprivsData() {
    this.ssoService.getuserprivs().subscribe((res: any) => {
      console.log("res", res)
      if (res.usertype == 'rw') {
        this.editFlag = true
      } else {
        this.editFlag = false
      }
    })
  }
}

export class InvestigateCatalog {
  data: any;
  isDisabled: boolean;

  constructor(InvestigateCatalog: InvestigateCatalog = null) {
    if (InvestigateCatalog != null) {
      this.isDisabled = InvestigateCatalog.isDisabled;
      this.data = InvestigateCatalog.data;
    } else {
      this.isDisabled = false;
    }
  }



}


