import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'cybot-investigation-view',
  templateUrl: './cybot-investigation-view.component.html',
  styleUrls: ['./cybot-investigation-view.component.scss']
})
export class CybotInvestigationViewComponent implements OnInit {
  values = {};
  hunt_detection_id
  user_name
  @Input() set huntDetection(val) {
    // console.log("huntDetection", val)
    this.hunt_detection_id = val
  }
  @Input() set UserName(val) {
    // console.log("huntDetection", val)
    this.user_name = val
  }
  @Input() set component(val) {
    this.dashService.getInvestigationJSON(val).subscribe(it => {
      this.values = it;
    });
    // console.log("this.values", this.values)
  }
 


  constructor(private dashService:DashboardService) { }

  ngOnInit(): void {
  }

}
