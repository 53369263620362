import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";
import { Observable, Subject, timer } from "rxjs";
import { CommonService } from "src/app/core/services/common.service";
import * as echarts from "echarts";
declare var $: any;
import * as moment from "moment";
import * as localization from "moment/locale/fr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
moment.updateLocale("fr", localization);
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import { DatePipe } from "@angular/common";
import {
  BarChartType,
  ChartType,
  EchartType,
  Stat,
} from "../view-dashboard.model";
import { Router } from "@angular/router";
import { ThreatIntelligenceService } from "src/app/core/services/threat-intelligence.service";


@Component({
  selector: "app-community-threat",
  templateUrl: "./community-threat.component.html",
  styleUrls: ["./community-threat.component.scss"],
})
export class CommunityThreatComponent implements OnInit {
  constructor(
    public common: ThreatIntelligenceService,
    private router: Router,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) {}

  // bar chart
  public communitybarChart: ChartType;
  public attributebarChart: ChartType;


  public communityPieChart;
  public attributePieChart;
  results = [];
  dtTrigger: Subject<any> = new Subject<any>();
  detectionTableData = [];
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 5,
    lengthMenu: [5, 10, 25],
    processing: true,
    order: [],
  };
  isEventDisribution1: boolean = false;
  isEventDisribution2: boolean = false;
  tableLoader: boolean = false;
  progressbar: boolean = true;
  printClick: boolean = true;
  communityFeedChartDataFlag: boolean = false;
  attributeFeedChartDataFlag: boolean = false;
  progressbarData = 1;
  period = {
    gte: "",
    lte: "",
  };
  detectionTableDataPrint = [];
  modalId;
  button = "Save PDF";
  isLoading = true;
  total$: Observable<number>;
  service = {
    page: 1,
    pageSize: 4,
  };

  setEchartTitleColor: boolean = false;

  // setting community feeds

  breadCrumbItems: Array<{}>;
  detectionTableDatatemp = [];
  detectionTableData1 = [];
  statData1: Stat[] = [
    {
      icon: "ri-stack-line",
      title: "Detections",
      value: "5",
    },
    {
      icon: "ri-store-2-line",
      title: "MITRE Detections",
      value: "4",
    },
    {
      icon: "ri-briefcase-4-line",
      title: "MISP based detections",
      value: "0",
    },
    {
      icon: "ri-briefcase-4-line",
      title: "Advanced analytics detections",
      value: "0",
    },
  ];

  statData2: Stat[] = [
    {
      icon: "ri-stack-line",
      title: "Number of Sales",
      value: "1452",
    },
    {
      icon: "ri-store-2-line",
      title: "Sales Revenue",
      value: "$ 38452",
    },
  ];
  donughnutChart: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Defense Evasion", "Privilege Escalation"],
      textStyle: { color: "#8791af" },
    },
    color: ["#556ee6", "#f1b44c"],
    series: [
      {
        name: "Tactics detected",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 20, name: "Defense Evasion" },
          { value: 70, name: "Privilege Escalation" },
        ],
      },
    ],
  };

  communityFeedChart: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "horizontal",
      x: "left",
      data: [],
      // textStyle: this.setEchartTitleColor === true ? {color: "black"} : {color: "rgb(238, 238, 238)"}
      textStyle: { color: "rgb(238, 238, 238)" },
    },
    color: ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"],
    series: [
      {
        name: "FEED NAME",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: "", name: "CIRCL" },
          { value: "", name: "Completed" },
          { value: "", name: "InProgress" },
        ],
        center: ["50%", "60%"],
      },
    ],
  };

  attributeFeedChart: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    // legend: {
    //   show: true,
    //   position: "bottom",
    //   horizontalAlign: "center",
    //   verticalAlign: "start",
    // },
    legend: {
      orient: "horizontal",
      x: "left",
      data: [],
      textStyle: { color: "rgb(238, 238, 238)" },
    },
    color: ["#1cbb8c", "#5664d2", "#fcb92c", "#4aa3ff", "#ff3d60"],
    series: [
      {
        name: "Category",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: "", name: "CIRCL" },
          { value: "", name: "Completed" },
          { value: "", name: "InProgress" },
        ],
        center: ["50%", "60%"],
      },
    ],
  };

  feedDisribution: ChartType = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: [
      "#F3B415",
      "#F27036",
      "#663F59",
      "#6A6E94",
      "#4E88B4",
      "#00A7C6",
      "#18D8D8",
      "#A9D794",
      "#46AF78",
      "#A93F55",
      "#8C5E58",
      "#2176FF",
      "#33A1FD",
      "#7A918D",
      "#BAFF29",
    ],
    series: [
      {
        name: "CIRCL",
        data: [],
      },
      // {
      //   name: 'DIGITALSIDE.IT',
      //   data: []
      // },
      {
        name: "OTX",
        data: [],
      },
      // {
      //   name: 'CERT-FR_1510',
      //   data: []
      // }, {
      //   name: 'CUDESO',
      //   data: []
      // }, {
      //   name: 'CthuluSPRL.be',
      //   data: []
      // },
    ],
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return "Count" + val + "..";
        },
      },
    },
  };

  attributeCategory: ChartType = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: [
      "#6A6E94",
      "#4E88B4",
      "#00A7C6",
      "#18D8D8",
      "#A9D794",
      "#F3B415",
      "#F27036",
      "#663F59",
      "#46AF78",
      "#A93F55",
      "#8C5E58",
      "#2176FF",
      "#33A1FD",
      "#7A918D",
      "#BAFF29",
    ],
    series: [
      {
        name: "Network activity",
        // data: [46, 57, 59, 54, 62, 58, 64, 60, 66]
        data: [],
      },
      {
        name: "Payload delivery",
        data: [],
      },
      {
        name: "External analysis",
        data: [],
      },
      {
        name: "Antivirus detection",
        data: [],
      },
      {
        name: "Artifacts dropped",
        data: [],
      },
      {
        name: "Other",
        data: [],
      },
      {
        name: "Persistence mechanism",
        data: [],
      },
      {
        name: "Targeting data",
        data: [],
      },
    ],
    xaxis: {
      // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      categories: [],
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return "Count" + val + "";
        },
      },
    },
  };

  criticalobservables: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Hash", "IP", "URL"],
      textStyle: { color: "#8791af" },
    },
    color: ["#ff3d60", "#4aa3ff", "#fcb92c"],
    series: [
      {
        name: "Critical rated observables",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 4, name: "Hash" },
          { value: 2, name: "IP" },
          { value: 3, name: "URL" },
        ],
      },
    ],
  };

  highratedobservables: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Hash", "IP", "URL"],
      textStyle: { color: "#8791af" },
    },
    color: ["#bc2c46", "#5664d2", "#d69100"],
    series: [
      {
        name: "high rated observables",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 3, name: "Hash" },
          { value: 2, name: "IP" },
          { value: 0, name: "URL" },
        ],
      },
    ],
  };

  basicColumChart: BarChartType = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#d83452", "#3170b1", "#e1a426"],
    series: [
      {
        name: "Hash",
        data: [46],
      },
      {
        name: "IP",
        data: [74],
      },
      {
        name: "URL",
        data: [37],
      },
    ],
    xaxis: {
      categories: ["All"],
      title: {
        text: "All Docs",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return "Count : " + val;
        },
      },
    },
  };

  criticalrb: BarChartType = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#ff3d60", "#4aa3ff", "#fcb92c"],
    series: [
      {
        name: "Hash",
        data: [46],
      },
      {
        name: "IP",
        data: [74],
      },
      {
        name: "URL",
        data: [37],
      },
    ],
    xaxis: {
      categories: ["All"],
      title: {
        text: "All docs",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return "Count :  " + val;
        },
      },
    },
  };

  simplePieChart1: ChartType = {
    chart: {
      height: 320,
      type: "pie",
    },
    series: [44, 55, 41],
    labels: ["Hash", "IP", "URL"],
    colors: ["#1cbb8c", "#5664d2", "#fcb92c"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  ngOnInit(): void {
    if (localStorage.getItem("isDateRange") == "true") {
      this.period = {
        gte: localStorage.getItem("startDate"),
        lte: localStorage.getItem("endDate"),
      };
    } else {
      this.period = {
        gte: localStorage.getItem("period"),
        lte: "now",
      };
    }
    this.breadCrumbItems = [
      { label: "Home" },
      { label: "Community Threat Feeds", active: true },
    ];
    this.communityFeedChartData();
    this.attributeFeedChartData();
    this.getTopCharts();
    // this.getFeedDistributionBar();
    // this.getAttributeDistributionBar();


    this.communitybarChart = {
      series: [],
      chart: {
        type: "bar",
        height: 200,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '35%'
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: -10
      }
    }


    // bar attribute

    this.attributebarChart = {
      series: [],
      chart: {
        type: "bar",
        height: 200,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '35%'
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: -10
      }
    };
}

  ngAfterViewInit(): void {
    this.communityPieChart = echarts.init(
      document.getElementById("communityPie")
    );
    this.attributePieChart = echarts.init(
      document.getElementById("attributePie")
    );
  }

  isCommunityFeedLoading = false;
  communityFeedChartData() {
    this.isCommunityFeedLoading = true;
    let data = {
      size: 0,

      query: {
        bool: {
          must: [],

          filter: [
            {
              match_all: {},
            },

            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,

                  lte: this.period.lte,

                  format: "strict_date_optional_time",
                },
              },
            },
          ],

          should: [],

          must_not: [
            {
              match_phrase: {
                feed_name:
                  "Activebytes Innovations - Threat Intelligence Services",
              },
            },
            {
              match_phrase: {
                feed_name: "CTI",
              },
            },
            // {
            //   match_phrase: {
            //     feed_name: "OTX",
            //   },
            // },
          ],
        },
      },

      aggs: {
        "2": {
          terms: {
            field: "feed_name",

            order: {
              _count: "desc",
            },

            size: 5,
          },
        },
      },
    };
    this.common.getCommunityFeedChart(data).subscribe(
      (response: any) => {
        this.isCommunityFeedLoading = false;
        let buckets = response.aggregations[2].buckets;
        let datas = [];
        let series = [];
        this.communityFeedChart.legend.data = [];
        if (buckets.length > 0) {
          for (let i in response.aggregations[2].buckets) {
            datas.push({
              value: buckets[i] != undefined ? buckets[i].doc_count : 0,
              name: buckets[i] != undefined ? buckets[i].key : "",
            });
            this.communityFeedChart.legend.data.push(
              buckets[i] != undefined ? buckets[i].key : ""
            );

            series.push({
              name: [buckets[i].key],
              data: [buckets[i].doc_count]
            })

          }

          this.communitybarChart.series = series
          this.communityFeedChart.series[0].data = datas;
          this.communityPieChart.setOption(this.communityFeedChart);
        } else {
          this.communityPieChart.dispose();
          this.communityFeedChartDataFlag = true;
          document.getElementById("communityPie").innerHTML =
            "<div class='m-auto text-danger'>Not Enough Data</div>";
          document.getElementById("communityPie1").innerHTML =
            "<div class='m-auto text-danger'>Not Enough Data</div>";
        }
      },
      (error) => {
        this.isCommunityFeedLoading = false;
      }
    );
  }

  isAttributeFeedLoading = false;
  attributeFeedChartData() {
    let data = {
      aggs: {
        "2": {
          terms: {
            field: "attribute_category",
            order: {
              _count: "desc",
            },
            size: 5,
          },
        },
      },
      size: 0,
      stored_fields: ["*"],
      script_fields: {
        "Event drilldown": {
          script: {
            source: "doc['event_id'].value",
            lang: "painless",
          },
        },
      },
      docvalue_fields: [
        {
          field: "attribute_timestamp",
          format: "date_time",
        },
      ],
      _source: {
        excludes: [],
      },
      query: {
        bool: {
          must: [],
          filter: [
            {
              match_all: {},
            },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.isAttributeFeedLoading = true;
    this.common.getAttributecategoryChart(data).subscribe(
      (response: any) => {
        this.isAttributeFeedLoading = false;
        let buckets = response.aggregations[2].buckets;
        let datas = [];
        let series = []
        this.attributeFeedChart.legend.data = [];
        if (buckets.length > 0) {
          for (let i in response.aggregations[2].buckets) {
            datas.push({
              value: buckets[i] != undefined ? buckets[i].doc_count : 0,
              name: buckets[i] != undefined ? buckets[i].key : "",
            });
            series.push({
              name: [buckets[i].key != '' ? buckets[i].key : 'Others'],
              data: [buckets[i].doc_count]
            })

            this.attributeFeedChart.legend.data.push(
              buckets[i] != undefined ? buckets[i].key : ""
            );
          }
          this.attributebarChart.series = series.reverse()
          this.attributeFeedChart.series[0].data = datas;
          this.attributePieChart.setOption(this.attributeFeedChart);
        } else {
          this.attributeFeedChartDataFlag = true;
          this.attributePieChart.dispose();
          document.getElementById("attributePie").innerHTML =
            "<div class='m-auto text-danger'>Not Enough Data</div>";
          document.getElementById("attributePie1").innerHTML =
            "<div class='m-auto text-danger'>Not Enough Data</div>";
        }
      },
      (error) => {
        this.isAttributeFeedLoading = false;
      }
    );
  }

  getTopCharts() {
    this.isLoading = true;
    let data1 = {
      size: 0,
      aggs: {
        "2": {
          terms: { field: "event_name", order: { _key: "desc" }, size: 100 },
          aggs: {
            "3": {
              terms: { field: "feed_name", order: { _key: "desc" }, size: 5 },
              aggs: {
                "4": {
                  terms: {
                    field: "event_id",
                    order: { _key: "desc" },
                    size: 5,
                  },
                  aggs: {
                    "5": {
                      terms: {
                        field: "severity",
                        order: { _key: "desc" },
                        size: 5,
                      },
                      aggs: {
                        "1": {
                          top_hits: {
                            docvalue_fields: [
                              {
                                field: "attribute_timestamp",
                                format: "date_time",
                              },
                            ],
                            _source: "attribute_timestamp",
                            size: 1,
                            sort: [{ attribute_timestamp: { order: "desc" } }],
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [
            {
              match_phrase: {
                feed_name:
                  "Activebytes Innovations - Threat Intelligence Services",
              },
            },
            { match_phrase: { feed_name: "CTI" } },
          ],
        },
      },
    };
    this.tableLoader = true;
    this.common.getCommunityFeedChart(data1).subscribe(
      (response: any) => {
        if (response) {
          // let aggregations = response.aggregations;
          this.detectionTableData = [];
          this.detectionTableDatatemp = [];
          let k = 0;
          let buckets = response?.aggregations[2].buckets;
          let datas = [];
          this.detectionTableData = [];
          for (let i in buckets) {
            datas.push({
              event: buckets[i]?.key,
              feed: buckets[i][3]?.buckets[0]?.key,
              eventid: buckets[i][3]?.buckets[0][4]?.buckets[0]?.key,
              severity:
                buckets[i][3]?.buckets[0][4]?.buckets[0][5]?.buckets[0]?.key,
              attribute_timestamp:
                buckets[i][3]?.buckets[0][4]?.buckets[0][5]?.buckets[0][1]?.hits
                  .hits[0]?._source?.attribute_timestamp,
              // drilldown:
            });
          }

          
          if (datas && datas.length) {
            datas = datas.sort((a, b) =>
              a.attribute_timestamp > b.attribute_timestamp ? -1 : 1
            );

            let j = 0
            datas.forEach(data=>{
              this.detectionTableDatatemp.push([j+1, this.timeConvert(data.attribute_timestamp), data.feed, data.event, data.severity])
              j++
            })
          }

          this.detectionTableData = datas;
          this.isLoading = false;
          this.dtTrigger.next();
        }
        this.tableLoader = false;
      },
      (error) => {
        this.isLoading = false;
        this.tableLoader = false;
      }
    );
  }

  getFeedDistributionBar() {
    let data = {
      size: 0,
      query: {
        bool: {
          must: [],
          filter: [
            {
              match_all: {},
            },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [
            {
              match_phrase: {
                feed_name:
                  "Activebytes Innovations - Threat Intelligence Services",
              },
            },
          ],
        },
      },
      aggs: {
        "2": {
          date_histogram: {
            field: "attribute_timestamp",
            calendar_interval: "1d",
            time_zone: "Asia/Kolkata",
            min_doc_count: 1,
          },
          aggs: {
            "3": {
              terms: {
                field: "feed_name",
                order: {
                  _count: "desc",
                },
                size: 5,
              },
            },
          },
        },
      },
    };
    this.isEventDisribution1 = true;
    this.common.getCommunityFeedChart(data).subscribe(
      (response: any) => {
        this.isEventDisribution1 = false;
        if (response) {
          let dataSeries = [...this.feedDisribution.series];

          for (let k in this.feedDisribution.series) {
            dataSeries[k].data = new Array(
              response.aggregations[2].buckets.length
            ).fill(0);
          }

          for (let i = 0; i < response.aggregations[2].buckets.length; i++) {
            this.feedDisribution.xaxis.categories.push(
              moment
                .unix(response.aggregations[2].buckets[i].key / 1000)
                .format("DD/MM/YYYY")
            );
            for (let j in response.aggregations[2].buckets[i][3].buckets) {
              for (let k in this.feedDisribution.series) {
                if (
                  dataSeries[k].name ==
                  response.aggregations[2].buckets[i][3].buckets[j].key
                ) {
                  dataSeries[k].data[i] =
                    response.aggregations[2].buckets[i][3].buckets[j].doc_count;
                }
                dataSeries[k].data = Array.from(
                  dataSeries[k].data,
                  (item) => item || 0
                );
              }
            }
          }
          this.feedDisribution.series = dataSeries;
          if (response.aggregations[2].buckets.length == 0) {
            document.getElementById("feedDisribution").innerHTML =
              "<div class='mhbar d-flex text-danger'><div class='m-auto'>Not Enough Data</div></div>";
          }
        }
      },
      (error) => {
        this.isEventDisribution1 = false;
      }
    );
  }

  getAttributeDistributionBar() {
    let data = {
      size: 0,
      query: {
        bool: {
          must: [],
          filter: [
            {
              match_all: {},
            },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [
            {
              match_phrase: {
                feed_name:
                  "Activebytes Innovations - Threat Intelligence Services",
              },
            },
          ],
        },
      },
      aggs: {
        "2": {
          date_histogram: {
            field: "attribute_timestamp",
            calendar_interval: "1d",
            time_zone: "Asia/Kolkata",
            min_doc_count: 1,
          },
          aggs: {
            "3": {
              terms: {
                field: "attribute_category",
                order: {
                  _count: "desc",
                },
                size: 5,
              },
            },
          },
        },
      },
    };
    this.common.getCommunityFeedChart(data).subscribe(
      (response: any) => {
        if (response) {
          let dataSeries = [...this.attributeCategory.series];

          for (let k in this.attributeCategory.series) {
            dataSeries[k].data = new Array(
              response.aggregations[2].buckets.length
            ).fill(0);
          }

          for (let i = 0; i < response.aggregations[2].buckets.length; i++) {
            this.attributeCategory.xaxis.categories.push(
              moment
                .unix(response.aggregations[2].buckets[i].key / 1000)
                .format("DD/MM/YYYY")
            );
            for (let j in response.aggregations[2].buckets[i][3].buckets) {
              for (let k in this.attributeCategory.series) {
                if (
                  dataSeries[k].name ==
                  response.aggregations[2].buckets[i][3].buckets[j].key
                ) {
                  dataSeries[k].data[i] =
                    response.aggregations[2].buckets[i][3].buckets[j].doc_count;
                }
                dataSeries[k].data = Array.from(
                  dataSeries[k].data,
                  (item) => item || 0
                );
              }
            }
          }
          this.attributeCategory.series = dataSeries;
          if (response.aggregations[2].buckets.length == 0) {
            document.getElementById("attributeCategory").innerHTML =
              "<div class='mhbar d-flex text-danger'><div class='m-auto'>Not Enough Data</div></div>";
          }
        }
      },
      (error) => {
      }
    );
  }

  getItems(names) {
    const a: any[] = [];
    for (let i = 0; i < names.length; i++) {
      a.push(
        names.reduce((acc, val) => {
          acc[val.key] = val.doc_count;
          return acc;
        }, {})
      );
    }
    return a;
  }

  getPdf(){
    this.printClick = false;
    var data1 = document.getElementById("contentToConvert");
    // var data2 = document.getElementById("contentToConvert1");
    // var data3 = document.getElementById("contentToConvert2");
    let head = [
      [
        "Sl No",
        "Event Time",
        "Feed Name",
        "Event Name",
        "Severity",
      ],
    ];
    html2canvas(data1).then((canvas) => {
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      imgHeight = imgHeight - 25;
      let pdf = new jspdf("p", "mm", "a4");
      var img = new Image();
      img.src = "assets/images/logo-dark.png";
          if (this.detectionTableDatatemp.length > 0) {
            pdf.addImage(img, "png", 150, 5, 45, 15);
            var header = function (data) {
              pdf.addImage(img, "png", 150, 5, 45, 15);
            };
            (pdf as any).autoTable({
              columnStyles: {
                0: { halign: "center", cellWidth: 13 },
                1: { halign: "left", cellWidth: 25 },
                2: { halign: "center", cellWidth: 30 },
                3: { halign: "center", cellWidth: 85 },
                4: { halign: "center", cellWidth: 20 },
                5: { halign: "center", cellWidth: 20 },
              },
              margin: { top: 25, left: 8 },
              head: head,
              body: this.detectionTableDatatemp,
              beforePageContent: header,
              theme: "grid",
            });
            pdf.save("community-threat.pdf");
            this.printClick = true;
            this.modalService.dismissAll("Ok click");
          } else {
            pdf.save("community-threat.pdf");
            this.printClick = true;
            this.modalService.dismissAll("Ok click");
          }
        });
  }

  // set color for pdf and Ui


  tableArry() {
    var perChunk = 20;
    var inputArray = this.detectionTableDatatemp;
    var result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    this.detectionTableDataPrint = result;
  }

  runProgressBar() {
    timer(0, 100).subscribe(() => {
      this.progressbarData = this.progressbarData + 1;
    });
  }
  isWidthWithinLimit() {
    if (this.progressbarData === 100) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.communityPieChart.dispose();
    this.attributePieChart.dispose();
  }
  timeConvert(t) {
    let time = this.datePipe.transform(t * 1000, "medium");
    return time;
  }

  // click() {
  //   this.isLoading = true;
  //   this.button = 'Processing';

  //   setTimeout(() => {
  //     this.isLoading = false;
  //     this.button = 'Submit';
  //     alert('Done loading');
  //   }, 8000)
  // }
  goToBack() {
    this.router.navigate(["/dashboard", { ti: 1 }]);
  }
  recurring(index, buckets, values) {
    buckets.forEach((b) => {
      let temp = values;
      if (b[index + 1]) {
        if (
          Array.isArray(b[index + 1].buckets) &&
          b[index + 1].buckets.length > 0
        ) {
          if (temp !== "") temp += ` ${b.key}`;
          else temp += `${b.key}`;
          this.recurring(index + 1, b[index + 1].buckets, temp);
        } else {
          let k = temp + ` ${b.key}`;
          this.results.push(k.split(" "));
        }
      } else {
        let k = temp + ` ${b.key}`;
        this.results.push(k.split(" "));
      }
    });
  }
}
