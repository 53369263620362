<app-topbar></app-topbar>
<div class="container mt-top">
    <div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <ng-container *ngIf="isLoaderShow">
                        <div class="d-flex pabs100 mt-9"><img class="m-auto" width="400"
                                src="assets/images/loading.gif" /></div>
                    </ng-container>
                    <div class="card-body" *ngIf="!isLoaderShow">
                        <h4 _ngcontent-enw-c171="" class="card-title">HUNTER</h4>
                        <div></div>
                        <div class="select-and-move-outer">
                            <div class="top-buttons">
                                <a href="javascript:void(0)" (click)="onRefresh()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                        <path
                                            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                                    </svg>
                                    <span>Refresh</span>
                                </a>
                                <a class="secondary" href="javascript:void(0)" (click)="gotoCustom()">
                                    <img class="m-auto" width="18px" style="margin-right: 5px !important;"
                                        src="assets/images/writing.png" />
                                    <span>Custom</span>
                                </a>
                            </div>
                            <div class="select-and-move-wrapper">

                                <div class="row ">
                                    <div class="col-md-6">
                                        <div class="available-box">

                                            <div class="head-text">MASTER CATALOGUE:</div>
                                            <ng-container *ngIf="isInputShow">
                                                <div class="d-flex pabs100 mt-9"><img class="m-auto" width="100%"
                                                        src="assets/images/loading.gif" /></div>
                                            </ng-container>
                                            <ng-container *ngIf="!isInputShow">
                                                <div class="bgBox">
                                                    <a href="javascript:void(0)"
                                                        *ngFor="let inputCatalogue of masterCatalogue"
                                                        [ngClass]="{'disabled-link':inputCatalogue.isDisabled,'selected':inputCatalogue.isSelected}">
                                                        <div class="left-section">
                                                            <div class="catelogueHead">{{inputCatalogue.data[0]}}</div>
                                                            <div class="subCategory">
                                                                {{inputCatalogue.data[1]}}<br><span>{{inputCatalogue.data[2]}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="icon-holder" (click)="onAdd(inputCatalogue)">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512">
                                                                <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                                                <path
                                                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <!-- <div class="col-md-2"></div> -->

                                    <div class="col-md-6">
                                        <div class="selected-box">
                                            <div class="head-text">ORGANIZATION CATALOGUE:</div>
                                            <ng-container *ngIf="isOutputShow">
                                                <div class="d-flex pabs100 mt-9"><img class="m-auto" width="100%"
                                                        src="assets/images/loading.gif" />
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!isOutputShow">
                                                <div class="bgBox">
                                                    <a href="javascript:void(0)"
                                                        *ngFor="let outputCatalogue of tenantCatalogue"
                                                        [ngClass]="{'disabled-link':outputCatalogue.isDisabled,'selected':outputCatalogue.isSelected}">
                                                        <div class="left-section">
                                                            <div class="catelogueHead">{{outputCatalogue.data[0]}}</div>
                                                            <div class="subCategory">
                                                                {{outputCatalogue.data[1]}}<br><span [innerHTML]="outputCatalogue.data[2]"></span>
                                                            </div>
                                                        </div>
                                                        <div class="icon-list">
                                                            <div class="icon-holder mb-2"
                                                                (click)="onRemove(outputCatalogue)">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 448 512">
                                                                    <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                                                    <path
                                                                        d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                                                </svg>
                                                            </div>
                                                            <div class="icon-holder" (click)="onEdit(outputCatalogue)">
                                                                <svg enable-background="new 0 0 19 19" height="19px"
                                                                    id="Layer_1" version="1.1" viewBox="0 0 19 19"
                                                                    width="19px" xml:space="preserve"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                    <g>
                                                                        <path
                                                                            d="M8.44,7.25C8.348,7.342,8.277,7.447,8.215,7.557L8.174,7.516L8.149,7.69   C8.049,7.925,8.014,8.183,8.042,8.442l-0.399,2.796l2.797-0.399c0.259,0.028,0.517-0.007,0.752-0.107l0.174-0.024l-0.041-0.041   c0.109-0.062,0.215-0.133,0.307-0.225l5.053-5.053l-3.191-3.191L8.44,7.25z"
                                                                            fill="#231F20" />
                                                                        <path
                                                                            d="M18.183,1.568l-0.87-0.87c-0.641-0.641-1.637-0.684-2.225-0.097l-0.797,0.797l3.191,3.191l0.797-0.798   C18.867,3.205,18.824,2.209,18.183,1.568z"
                                                                            fill="#231F20" />
                                                                        <path
                                                                            d="M15,9.696V17H2V2h8.953l1.523-1.42c0.162-0.161,0.353-0.221,0.555-0.293   c0.043-0.119,0.104-0.18,0.176-0.287H0v19h17V7.928L15,9.696z"
                                                                            fill="#231F20" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                    </a>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>


                                </div>

                                <!-- <div class="action-buttons-wrapper">
                                <div class="action-buttons">
                                    <a href="javascript:void(0)">Add</a>
                                    <a href="javascript:void(0)">Add All</a>
                                    <a href="javascript:void(0)" class="secondary">Remove</a>
                                    <a href="javascript:void(0)">Remove All</a>
                                    <div class="selected-button">
                                        <a href="javascript:void(0)" class="secondary">Selected</a>
                                    </div>
                                </div>
                            </div> -->

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>