import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const apiUrl = localStorage.getItem('cyboturl') + '/cybot_proxy';
@Injectable({
  providedIn: 'root'
})
export class CorrelationStudioService {

  HttpOptions: any;
  constructor(private http: HttpClient, private router: Router) { }
  handleError(error: HttpErrorResponse) {
    console.log("error", error);
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
  thpObservables(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .post<object>(
        apiUrl + "/thp_observables*/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
}
