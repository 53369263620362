import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CommonService } from "src/app/core/services/common.service";
import { SsoService } from "src/app/core/services/sso.service";
import { DatePipe } from "@angular/common";
import { Subject } from "rxjs";
import { ThreatIntelligenceService } from "src/app/core/services/threat-intelligence.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { UrlHelperService } from "src/app/core/services/url-helper.service";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  // pagination
  totalPageLength: number;
  defaultpage: number = 1;
  instanceDataTime = []

  loader: boolean = true
  isFilteredTimeStamp = false
  // A-code
  period = {
    gte: "",
    lte: "",
  };

  headlinesData = [];
  dtTriggerHeadlines: Subject<any> = new Subject<any>();

  selectedItem = "";
  routerUrl;
  analytics;
  compliance;
  hunter;
  investigate;
  rulesstudio;
  rulesstudioadmin;
  ti;
  ueba;
  compliance_ctrl_view = true;
  compliance_alerts = true;
  compliance_dashboard = true;
  currentPage;
  showFilter;
  isActivePie = false;
  showIFrame = false
  showIFrameCompliance = false
  iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("https://54.177.228.17:5600/")
  iframeUrlCompliance
  @ViewChild('iframe') iframe: ElementRef;
  constructor(private datePipe: DatePipe, private urlHelper: UrlHelperService,
    private SsoService: SsoService, private router: Router, private common: CommonService, private threat: ThreatIntelligenceService, private domSanitizer: DomSanitizer) {
  }



  ngOnInit() {
    this.isActivePie = true;
    this.routerUrl = this.router.url;
    let s = this.routerUrl.split(";");
    if (s[1] != undefined) {
      if (s[1] == "ti=1") {
        this.selectedItem = "threat";
      } else if (s[1] == "cm=1") {
        this.selectedItem = "compliance";
      }
    }
    this.getlicenseinfo();
    this.SsoService.getLoggedInUserDetails().subscribe((data) => {
      let userData: any = data;
      if (userData.username) {
        localStorage.setItem("USER_NAME", userData.username);

      }
      if (userData.email) {
        localStorage.setItem("Email", userData.email);
      }
    });
    this.SsoService.getSystemvArs().subscribe((data: any) => {
      if (data.variables)
        data.variables.forEach(async element => {
          // console.log("element.name", element.name)
          if (element.name == 'analytics-ui-url') {
            // console.log("element.name", element.value)
            this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(element.value)
            //this.urlHelper.get(element.value).subscribe(blob => this.iframe.nativeElement.src = blob)
          }
          if (element.name == 'compliance-ui-url') {
            localStorage.setItem("compliance-ui-url", element.value);
            this.iframeUrlCompliance = this.domSanitizer.bypassSecurityTrustResourceUrl(element.value)
          }
        });

      // localStorage.setItem("datalakeurl", data.datalakeurl);

      if (data.datalakeurl)
        localStorage.setItem("datalakeurl", data.datalakeurl);
      if (data.datalakepass)
        localStorage.setItem("datalakepass", data.datalakepass);
      if (data.datalakeuname) {
        localStorage.setItem("datalakeuname", data.datalakeuname);
        localStorage.setItem("ACCESS_TOKEN",
          "Basic " + btoa(data.datalakeuname + ":" + data.datalakepass));
      }
      if (data.datalakeurl_dev)
        localStorage.setItem("datalakeurl_dev", data.datalakeurl_dev);
      if (data.datalakepass_dev)
        localStorage.setItem("datalakepass_dev", data.datalakepass_dev);
      if (data.datalakeuname_dev)
        localStorage.setItem("datalakeuname_dev", data.datalakeuname_dev);

      if (data.cybotpass)
        localStorage.setItem("cybotpass", data.cybotpass);
      if (data.cybotuname)
        localStorage.setItem("cybotuname", data.cybotuname);
      let user = data.cybotuname.split("_")
      let tenantID = user[0]
      // console.log(user, tenantID)
      localStorage.setItem("tenantID", tenantID);
      if (data.cyboturl)
        localStorage.setItem("cyboturl", data.cyboturl);

      let userData: any = data;
      if (userData.cybotuname && userData.cybotpass && userData.cyboturl) {
        // console.log("CYBOT_ACCESS_TOKEN_if")
        let data = {
          username: userData.cybotuname,
          password: userData.cybotpass,
        };
        this.SsoService.cybot_login(data, userData.cyboturl).subscribe(
          (res: any) => {
            // console.log("CYBOT_ACCESS_TOKEN", res.user_token)
            localStorage.setItem("CYBOT_ACCESS_TOKEN", res.user_token);
          },
          (error) => {
          }
        );
      } else {
      }
    });

    // getLatestNews() A-code

    if (localStorage.getItem("isDateRange") == "true") {
      this.period = {
        gte: localStorage.getItem("startDate"),
        lte: localStorage.getItem("endDate"),
      };
    } else {
      this.period = {
        gte: localStorage.getItem("period"),
        lte: "now",
      };
    }

    this.getHeadlinesData();

  }



  // headline section codes

  getHeadlinesData() {
    let data1 = {
      size: 10000,
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              match_phrase: {
                feed_name:
                  "CTI",
              },
            },
            { match_phrase: { event_category: "News" } },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
      aggs: {
        "1": {
          top_hits: {
            docvalue_fields: [
              {
                field: "attribute_timestamp",
                format: "date_time",
              },
            ],
            _source: "attribute_timestamp",
            size: 1,
            sort: [{ attribute_timestamp: { order: "desc" } }],
          },
        },
      },
    };
    this.threat.getCommunityFeedChart(data1).subscribe((response: any) => {
      // console.log("getHeadlinesData", response);
      if (response) {
        let hits = response.hits.hits;
        this.headlinesData = [];
        // console.log("my consoleeee...... headline Datas", this.headlinesData);
        for (let i = 0; i < hits.length; i++) {
          this.headlinesData.push({
            attribute_timestamp: this.timeConvert1(
              hits[i]._source.attribute_timestamp
            ),
            event_category: hits[i]._source.event_category,
            event_description: hits[i]._source.event_description,
            event_id: hits[i]._source.event_id,
            event_name: hits[i]._source.event_name,
            feed_name: hits[i]._source.feed_name,
            news: hits[i]._source.news,
            reference_link: hits[i]._source.reference_link,
            severity: hits[i]._source.severity,
            tlp: hits[i]._source.tlp,
          });
        }

        if (this.headlinesData && this.headlinesData.length) {
          this.headlinesData = this.headlinesData.reverse();
        }

        this.initialNews()

        this.totalPageLength = this.headlinesData.length;
        // console.log("The actual Length is :", this.totalPageLength);
        this.dtTriggerHeadlines.next();
        this.loader = false

        this.getAllNewsFilterBtns()
      }
    });
    (error) => {
      console.log("error", error);
    };
  }


  getAllNewsFilterBtns() {
    let buttons = document.querySelectorAll('#filterNewsBtn')
    buttons.forEach((button) => {
      button.addEventListener('click', this.setActiveStyle)
    })
  }

  setActiveStyle(e) {
    const buttons = document.querySelectorAll('#filterNewsBtn')
    buttons.forEach(buttons => {
      buttons.classList.remove('active');
    })
    e.target.classList.add('active')
  }

  // filtering time

  ShowLatestNews(filterTime: string) {
    if (filterTime === 'Latest') {
      this.instanceDataTime = [...this.headlinesData]
    }
  }

  initialNews() {
    this.instanceDataTime = [...this.headlinesData]
    const currentTime: any = new Date();
    let timePeriodInMilliSeconds
    timePeriodInMilliSeconds = 12 * 60 * 60 * 1000;

    let newFetchedTimeStamp = this.instanceDataTime.map(items => items)
    const filterTimestamp = newFetchedTimeStamp.filter(timestamps => {
      const timeStampDate: any = new Date(timestamps.attribute_timestamp);
      return currentTime - timeStampDate <= timePeriodInMilliSeconds;
    });

    this.instanceDataTime = filterTimestamp;
  }


  filterTimeByHours(filterTime: string) {


    this.instanceDataTime = [...this.headlinesData]

    this.isFilteredTimeStamp = true
    const currentTime: any = new Date();
    let timePeriodInMilliSeconds

    if (filterTime === '3H') {
      timePeriodInMilliSeconds = 3 * 60 * 60 * 1000;
    } else if (filterTime === '6H') {
      timePeriodInMilliSeconds = 6 * 60 * 60 * 1000;
    } else if (filterTime === '12H') {
      timePeriodInMilliSeconds = 12 * 60 * 60 * 1000;
    } else {
      this.headlinesData
    }

    let newFetchedTimeStamp = this.instanceDataTime.map(items => items)
    const filterTimestamp = newFetchedTimeStamp.filter(timestamps => {
      const timeStampDate: any = new Date(timestamps.attribute_timestamp);
      return currentTime - timeStampDate <= timePeriodInMilliSeconds;
    });

    this.instanceDataTime = filterTimestamp;
  }


  // headline section codes ends

  getlicenseinfo() {
    this.SsoService.getlicenseinfo().subscribe((data: any) => {
      // console.log("getlicenseinfo", data);
      this.analytics = data.analytics;
      this.compliance = data.compliance;
      this.hunter = data.hunter;
      this.investigate = data.investigate;
      this.rulesstudio = data.rulesstudio;
      this.rulesstudioadmin = data.rulesstudioadmin;
      this.ti = data.ti;
      this.ueba = data.ueba;
      this.compliance_ctrl_view = data["compliance-ctrl_view"] ? data["compliance-ctrl_view"] !== "false" : true;
      this.compliance_alerts = data["compliance-alerts"] ? data["compliance-alerts"] !== "false" : true;
      this.compliance_dashboard = data["compliance-dashboards"] ? data["compliance-dashboards"] !== "false" : true;
      // console.log("this.analytics", this.analytics);
      this.isActivePie = false;
    });
  }
  selectCard(val: string) {
    // console.log("val", val);
    // if (val == "analytics" || val == "community-threat") {
    //   this.router.navigateByUrl("/" + val);
    // } else if (val == "investigation") {
    //   this.router.navigateByUrl("/investigation/list");
    // } else if (val == "custom-hunting") {
    //   this.router.navigateByUrl("/custom-hunting/list");
    // } else if (val == "management-summary") {
    //   this.router.navigateByUrl("/management-summary");
    // } else if (val == "activebytes-threat") {
    //   this.router.navigateByUrl("/activebytes-threat");
    // } else if (this.selectedItem == "compliance") {
    //   if (val == "alert") {
    //     this.router.navigateByUrl("/alert");
    //   } else if (val == "controlview") {
    //     this.router.navigateByUrl("/controlview/complaints-iso27001");
    // console.log("val", val)
    if (val == 'analytics' || val == "community-threat") {
      this.router.navigateByUrl('/' + val);
    }
    else if (val == 'investigation') {
      this.router.navigateByUrl('/investigation/list');
    }
    else if (val == 'investigate') {
      this.router.navigateByUrl('/investigation');
    }
    else if (val == 'custom-hunting') {
      this.router.navigateByUrl('/custom-hunting/list');
    }
    else if (val == 'management-summary') {
      this.router.navigateByUrl('/management-summary');
    }
    else if (val == 'activebytes-threat') {
      this.router.navigateByUrl('/activebytes-threat');
    }
    else if (this.selectedItem == 'compliance') {
      if (val == 'alert') {
        this.router.navigateByUrl('/alert');
      } else if (val == 'controlview') {
        this.router.navigateByUrl('/controlview/complaints-iso27001');
      } else {
        this.router.navigateByUrl("/compliance/" + val);
      }
    } else if (val == "ueba") {
      this.router.navigateByUrl("/ueba");
    }
    else if (val == 'rule-studio') {
      this.router.navigateByUrl('/rule-studio');
    }
    else if (val == 'hunter') {
      this.router.navigateByUrl('/hunter');
    }
    this.selectedItem = val;
  }
  goToBack1() {
    this.router.navigateByUrl("/dashboard");
  }

  // A-CODE
  timeConvert1(t) {
    let time = this.datePipe.transform(t * 1000, "medium");
    return time;
    this.router.navigateByUrl('/dashboard');
  }
}
