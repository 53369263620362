import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { SettingsService } from 'src/app/core/services/settings.service';
import { AddExceptionsComponent } from '../add-exceptions/add-exceptions.component';
import { AddIntegrationsComponent } from '../add-integrations/add-integrations.component';

@Component({
  selector: 'app-list-integrations',
  templateUrl: './list-integrations.component.html',
  styleUrls: ['./list-integrations.component.scss']
})
export class ListIntegrationsComponent implements OnInit {

  constructor(private settingsService: SettingsService, private modalService: NgbModal) { }
  @Input() type: any
  dtTrigger5: Subject<any> = new Subject<any>();
  tableData5 = [];
  loader = false
  tenantID
  isDtInitialized: boolean[] = [false];
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    processing: true,
    order: []
  };
  modalId: number;
  selectedContent: any;
  Details = "Details"

  ngOnInit(): void {
    console.log("type", this.type)
    this.tenantID = localStorage.getItem("tenantID");
    console.log("tenantID", this.tenantID)
    if (this.type == 'Investigate') {
      this.loadTable5()
    } else if (this.type == 'UEBA') {
      this.loadTable5()
    } else {
      this.tenantID = "*"
    }

  }

  addSoar() {
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
      return
    } else {
      let objArr = [];
      objArr[0] = "Create Integration";
      objArr[2] = "Create";
      objArr[5] = this.tenantID;
      objArr[4] = this.type;
      let ngbModalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        windowClass: "modal-dialog-all common-modal",
      };
      const modalRef = this.modalService.open(AddIntegrationsComponent, ngbModalOptions);
      modalRef.componentInstance.objArr = objArr;
      modalRef.result.then(
        (result) => { },
        (reason) => {
          // console.log("reason", reason);
          if (reason.status == "created") {
            let item = reason.data;
            this.tableData5.push(item);
          }
        }
      );
    }
  }
  editSoar(data, i) {

    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
      return
    } else {
      data.tenant_id = this.tenantID;
      let objArr = [];
      objArr[0] = "Soar Edit";
      objArr[1] = data;
      objArr[2] = "Update";
      objArr[3] = i;
      objArr[5] = this.tenantID;
      objArr[4] = this.type;
      let ngbModalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        windowClass: "modal-dialog-all common-modal",
      };
      const modalRef = this.modalService.open(AddIntegrationsComponent, ngbModalOptions);
      modalRef.componentInstance.objArr = objArr;
      modalRef.result.then(
        (result) => { },
        (reason) => {
          if (reason.status == "update") {
            let item = reason.data;
            this.tableData5[reason.index] = item;
          }
        }
      );
    }

  }

  deleteSoar(data, i) {
    // console.log("this.tenantID", this.tenantID)
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
      return
    } else {
      // this.alertService.confirm(
      //   `Are you sure to delete Soar ?`,
      //   status => {
      //     if (status == true) {
      let id = data.id;
      let tenantID = this.tenantID;
      this.settingsService.soarDelete(tenantID, id).subscribe(
        (response: any) => {
          // console.log("response", response);
          if (response.result == "deleted") {
            // console.log("response", response.result);
            this.tableData5.splice(i, 1);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
      // }
      //   }
      // );
    }



  }
  viewSoar(data, i, viewmodal: any) {
    // console.log(content, modalId);
    this.modalId = 5;
    this.selectedContent = data;
    this.Details = "Integrations Details"
    this.modalService.open(viewmodal, { size: "lg", centered: true });

  }

  loadTable5() {
    this.loader = true;
    let data = {
      query: {
        match_all: {},
      },
    };
    var table = $('#datatable5').DataTable();
    if (table)
      table.destroy();
    let tenantID = this.tenantID;
    if (tenantID == undefined || tenantID == "") {
      this.settingsService.apiListAll(data).subscribe(
        (response: any) => {
          let data = response.hits.hits;
          this.tableData5 = [];

          // console.log("datadatadata", data);
          data.forEach((item) => {
            if (item._source.api_name != undefined) {
              let t = {
                id: item._id,
                index: item._index,
                doc: item._doc,
                api_name: item._source.api_name,
                method: item._source.method,
                url: item._source.url,
                count: item._source.count,
                header: item._source.header,
                body: item._source.body,
              };
              this.tableData5.push(t);
            }
          });
          this.tableData5 = this.tableData5;
          this.dtTrigger5.next();
          this.isDtInitialized[5] = true;
          setTimeout(() => {
            this.loader = false;
          }, 600);
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      this.settingsService.apiList(data, tenantID).subscribe(
        (response: any) => {
          //// console.log("getapiList", response);
          let data = response.hits.hits;
          this.tableData5 = [];
          data.forEach((item) => {
            let t = {
              id: item._id,
              index: item._index,
              doc: item._doc,
              api_name: item._source.api_name,
              method: item._source.method,
              url: item._source.url,
              api_key: item._source.api_key,
              header: item._source.header,
              body: item._source.body,
            };
            this.tableData5.push(t);
          });
          this.tableData5 = this.tableData5;
          this.dtTrigger5.next();
          this.isDtInitialized[5] = true;
          setTimeout(() => {
            this.loader = false;
          }, 600);
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }
}
