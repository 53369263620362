import { Injectable } from '@angular/core';
import { throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
const apiUrl = localStorage.getItem('cyboturl') + '/cybot_proxy';

// const apiAlertDataUrl_dev = localStorage.getItem('datalakeurl_dev');
// const userData_dev = {
//   datalakeuname_dev: localStorage.getItem('datalakeuname_dev'),
//   datalakepass_dev: localStorage.getItem('datalakepass_dev')
// }
const apiAlertDataUrl_dev = localStorage.getItem('datalakeurl');
const userData_dev = {
  datalakeuname_dev: localStorage.getItem('datalakeuname'),
  datalakepass_dev: localStorage.getItem('datalakepass')
}
// to use


@Injectable({
  providedIn: 'root'
})
export class UebaService {

  constructor(private http: HttpClient) { }

  HttpOptions: any;

  handleError(error: HttpErrorResponse) {
    console.log("error", error);
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  uebaGroupDetails(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/ueba_group_details*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }

  uebaGroupedDetections(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/ueba_grouped_detections*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }

  uebaObservablesAll(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/ueba_non_repeated_detections*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }


  uebaUsualDataPattern(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/ueba_usual_data_pattern*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }

  uebaUsualData(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/ueba_non_repeated_detections*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }

  uebaUserDataPattern(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/ueba_user_data_pattern*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }

  uebaUserDataPatternTime(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/ueba_user_data_pattern_time*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }

  uebaBehaviourLog(data) {
    // data = JSON.stringify(data);
    // let authorizationData: any =
    //   localStorage.getItem('access_token');
    // return this.http.post<object>("https://datalakedev.active-bytes.com:9202/logs-*/_search?track_total_hits=true", data, {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json",
    //     // "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN'),
    //     "Authorization": "Bearer " + authorizationData
    //   }),
    // })
    //   .pipe(catchError(this.handleError));

    let authorizationData: any =
  "Basic " + btoa(userData_dev.datalakeuname_dev + ":" + userData_dev.datalakepass_dev)
this.HttpOptions = {
  headers: new HttpHeaders({
    "Authorization": authorizationData,
    "Content-Type": "application/json"
  })
};
    return this.http
      .post<object>(
        localStorage.getItem('datalakeurl') +'/logs-*/_search?track_total_hits=true',
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getGaugeData(data) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN'),
          "Content-Type": "application/json"
        })
      };
    }
    let cybotUrl= localStorage.getItem('cyboturl');
    return this.http
      .post<object>(
        cybotUrl+"/cybot_proxy/thp_ip_enrichment*/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  uebaInvestigationsDetails(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/thp_enriched_observables*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }

  uebaInvestigationsGroupDetails(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/thp_group*/_search", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }

}
