<!-- <h4 class="pb-4">Integrations</h4> -->
<!-- <div class="pb-2 text-right">
    <div class="col-md-8 f-r pb-2 createBtn">
        <button type="button " class="btn btn-success" (click)="addSoar()">
            Create
        </button>
    </div>
</div> -->
<div class="tabbarbtnbox">
    <button type="button " class="btn btn-success " (click)="addSoar() ">
        Create 
    </button>
</div>
<div class="gif-css col-md-12" [ngClass]="{ dis_on: loader, dis_off: !loader }">
    <img src="assets/images/fileloading.gif" />
</div>
<div [ngClass]="{ dis_on: !loader, dis_off: loader }" class="table-responsive">
    <table id="datatable5" datatable class="row-border hover scroll" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger5"
        style="width:100%">
        <thead>
            <tr class="set-width">
                <th>Api Name</th>
                <th>Method</th>
                <!-- <th>Api Key</th> -->
                <th>header</th>
                <th>body</th>
                <th>Url</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tableData5; index as i">
                <td>{{ item.api_name }}</td>
                <td>{{ item.method }}</td>
                <!-- <td>{{ item.api_key }}</td> -->
                <td>{{ item.header }}</td>
                <td>{{ item.body }}</td>
                <td>{{ item.url }}</td>
                <td>
                    <a href="javascript:void(0); " class="mr-1 text-warning" ngbTooltip="Edit">
                        <i class="fa fa-pencil-square-o font-size-18" (click)="editSoar(item, i)"></i>
                    </a>
                    <a href="javascript:void(0); " class="mr-1  text-danger" ngbTooltip="Delete">
                        <i class="fa fa-trash-o font-size-18" (click)="deleteSoar(item, i)"></i>
                    </a>
                    <a href="javascript:void(0); " class="text-info" (click)="viewSoar(item, i,viewModal)"
                        ngbTooltip="View">
                        <i class="fa fa-eye font-size-18"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #viewModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{Details}}</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click ')">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pb-4">
        <div class="row" *ngIf="modalId == 4">
            <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
            <div id="contentToConvert3">
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Comment : <br /><b>{{ selectedContent.comment }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Created At : <br /><b>{{ selectedContent.created_at }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Created By: <br /><b>{{ selectedContent.created_by }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Host Name: <br /><b>{{ selectedContent.host_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Playbook Id: <br /><b>{{ selectedContent.playbook_id }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Playbook Name: <br /><b>{{ selectedContent.playbook_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Source Ip: <br /><b>{{ selectedContent.source_ip }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        User Name: <br /><b>{{ selectedContent.user_name }}</b>
                    </p>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="modalId == 5">
            <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
            <div id="contentToConvert3">
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Api Name : <b>{{ selectedContent.api_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Method : <b>{{ selectedContent.method }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        header: <b>{{ selectedContent.header }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        body: <b>{{ selectedContent.body }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Url: <b>{{ selectedContent.url }}</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>