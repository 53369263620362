import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/core/services/common.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingsService } from "src/app/core/services/settings.service";
import { RuleStudioService } from "src/app/core/services/rule-studio.service";
@Component({
  selector: 'app-add-exceptions-ueba',
  templateUrl: './add-exceptions-ueba.component.html',
  styleUrls: ['./add-exceptions-ueba.component.scss']
})
export class AddExceptionsUebaComponent implements OnInit {

  @Input() objArr: any = [];
  organizationList = [];
  addExceptionsform: FormGroup;
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  err = false;
  passwordShow = false;
  submited = false;
  dataError = false;
  btnName = "Create";
  playbookIdData = [];
  tenantID;
  tenantCatalogue
  type
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private ruleStudioService: RuleStudioService
  ) { }
  label_list = [{
    "ueba_id": "UEBA-001",
    "playbook_name": "Logon detected outside of usual time for host"
  },
  {
    "ueba_id": "UEBA-002",
    "playbook_name": "Logon detected outside of usual time for user"
  },
  {
    "ueba_id": "UEBA-003",
    "playbook_name": "Logon detected to unusual host for user "
  },
  {
    "ueba_id": "UEBA-004",
    "playbook_name": "File activities detected outside of usual time for user "
  },
  {
    "ueba_id": "UEBA-005",
    "playbook_name": "Management activities detected outside of usual time for user"
  },
  {
    "ueba_id": "UEBA-006",
    "playbook_name": "Unusually high number of file modification activities detected for user"
  },
  {
    "ueba_id": "UEBA-008",
    "playbook_name": "Unusually high number of management activities detected for user "
  },
  {
    "ueba_id": "UEBA-009",
    "playbook_name": "Unusually high number of file deletion activities detected for user "
  },
  {
    "ueba_id": "UEBA-010",
    "playbook_name": "Unusually high number of account lockout activity detected for domain"
  },
  {
    "ueba_id": "UEBA-011",
    "playbook_name": "Unusually high number of file activities detected for user "
  },
  {
    "ueba_id": "UEBA-012",
    "playbook_name": "Unusually high number of logon failures detected for host"
  },
  {
    "ueba_id": "UEBA-013",
    "playbook_name": "Unusually high number of logon failures detected for user "
  },
  {
    "ueba_id": "UEBA-014",
    "playbook_name": "Internal network transfer detected outside of usual time for host "
  },
  {
    "ueba_id": "UEBA-015",
    "playbook_name": "Internal network transfer detected outside of usual time for user "
  },
  {
    "ueba_id": "UEBA-016",
    "playbook_name": "External network transfer detected outside of usual time for host"
  },
  {
    "ueba_id": "UEBA-017",
    "playbook_name": "External network transfer detected outside of usual time for user"
  }];
  ngOnInit(): void {
    this.tenantID = this.objArr[3];
    this.type = this.objArr[4];
    this.getData()
    this.addExceptionsform = this.formBuilder.group({
      ueba_id: ["", Validators.required],
      user_name: ["",Validators.required],
      host_name: ["",Validators.required],
      // time: [""],
      tenant_id:[""],
      comment: [""],
    });
    this.addExceptionsform.valueChanges.subscribe(() => {
      this.err = false;
    });
    this.getLicenseDetailsList();
    this.btnName = this.objArr[2];
    if (this.objArr[0] == "Exceptions Edit") {

      this.addExceptionsform.controls["ueba_id"].setValue(
        this.objArr[1].ueba_id
      );
      this.addExceptionsform.controls["user_name"].setValue(
        this.objArr[1].exception_user_name
      );
      this.addExceptionsform.controls["host_name"].setValue(
        this.objArr[1].exception_host_name
      );
      // this.addExceptionsform.controls["time"].setValue(
      //   this.objArr[1].time
      // );

    } else if (this.objArr[0] == "Exceptions Add") {
      this.addExceptionsform.controls["user_name"].setValue(
        this.objArr[1]._source.user_name
      );
      this.addExceptionsform.controls["host_name"].setValue(
        this.objArr[1]._source.host_name
      );

    } else {
      if (this.objArr[2] == "Create from UEBA") {
        // this.addExceptionsform.controls["user_name"].setValue(this.objArr[1]._source.user_name);
        this.addExceptionsform.patchValue({
          user_name: this.objArr[1].user_name,
          host_name: this.objArr[1].host_name,
          ueba_id: this.objArr[1].ueba_id,
          tenant_id: this.objArr[3],
        });
      }
      this.addExceptionsform.controls["tenant_id"].setValue(this.objArr[3]);
    }
  }

  ngAfterViewInit() {
    console.log("tenantID", this.tenantID);
    // this.playbookIdList();
  }

  exceptionsCreation() {
    this.submited = true
    let a = ["thp_exceptions_" + this.addExceptionsform.value.tenant_id];
    let data;
    let tenantID = this.addExceptionsform.value.tenant_id;
    let str = localStorage.getItem("USER_NAME");
    let date = new Date().toISOString();
    let obj
    console.log("this.addExceptionsform.value.playbook_id", this.addExceptionsform.value.playbook_id)
    if (this.type == 'Investigate') {
      obj = this.playbookIdData.find((o, i) => {
        if (o.playbook_id === this.addExceptionsform.value.playbook_id) {
          return o; // stop searching
        }
      });
      console.log("obj", obj)
    }
    else {
      obj = this.label_list.find((o, i) => {
        if (o.ueba_id === this.addExceptionsform.value.playbook_id) {
          return o; // stop searching
        }
      });
      console.log("obj", obj)
    }


    if (!this.addExceptionsform.valid) {
      return
    }
    if (this.objArr[0] == "Exceptions Edit") {

        let id = this.objArr[1].id;
        data = {
          // ueba_name: obj.playbook_name,
          ueba_id: this.addExceptionsform.value.ueba_id,
          exception_user_name: this.addExceptionsform.value.user_name,
          exception_host_name: this.addExceptionsform.value.host_name,
          tenant_id: this.addExceptionsform.value.tenant_id,
          time:this.addExceptionsform.value.time,
          exception_comment: this.addExceptionsform.value.comment,

        };
        // comment: this.addExceptionsform.value.comment,
        let data1 = {
          exception_user_name: this.addExceptionsform.value.user_name,
          exception_host_name: this.addExceptionsform.value.host_name,
          ueba_id:this.addExceptionsform.value.ueba_id,
          exception_comment: this.addExceptionsform.value.comment,
        };
        // comment: this.addExceptionsform.value.comment

        let data2 = {
          exception_user_name: "",
          exception_host_name: "",
          ueba_id:"",
          exception_comment: "",
        };
        // comment: "",

        if (JSON.stringify(data1) === JSON.stringify(data2)) {
          this.dataError = true
          return
        } else {
          this.dataError = false
        }
        let d1 = {
          doc: data,
        };

        this.settingsService.exceptionsUpdateUEBA(d1, tenantID, id).subscribe(
          (response: any) => {
            if (response.result == "updated") {
              let data = {
                ueba_id: obj.playbook_name,
                created_by: this.objArr[1].created_by,
                created_at: this.objArr[1].created_at,
                type: this.type,
                exception_user_name: this.addExceptionsform.value.user_name,
                exception_host_name: this.addExceptionsform.value.host_name,
                exception_comment: this.addExceptionsform.value.comment,
              
              };
              this.submited = false
              this.modalService.dismissAll({
                status: "update",
                data: data,
                index: this.objArr[3],
              });
            } else {
              this.err = true;
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
    
    } else if (this.objArr[0] == "Exceptions Creation") {
        data = {
          // ueba_name: obj.playbook_name,
          ueba_id: this.addExceptionsform.value.playbook_id,
          exception_user_name: this.addExceptionsform.value.user_name,
          exception_host_name: this.addExceptionsform.value.host_name,
          tenant_id: this.addExceptionsform.value.tenant_id,
          created_by: str,
          created_at: date,
          time:this.addExceptionsform.value.time,
          exception_comment: this.addExceptionsform.value.comment,
        };
        let data1 = {
          ueba_id:this.addExceptionsform.value.ueba_id,
          exception_user_name: this.addExceptionsform.value.user_name,
          exception_host_name: this.addExceptionsform.value.host_name,
          exception_comment: this.addExceptionsform.value.comment,
        };

        let data2 = {
          exception_user_name: "",
          exception_host_name: "",
          ueba_id:"",
          exception_comment: "",
        };

        if (JSON.stringify(data1) === JSON.stringify(data2)) {
          this.dataError = true
          return
        } else {
          this.dataError = false
        }
        this.settingsService.exceptionsCreationUEBA(data, tenantID).subscribe(
          (response: any) => {
            if (response.result == "created") {
              let data = {
                // ueba_name: obj.playbook_name,
                ueba_id: this.addExceptionsform.value.playbook_id,
                id: response._id,
                index: response._index,
                type: response._type,
                created_by: str,
                created_at: date,
                exception_user_name: this.addExceptionsform.value.user_name,
                exception_host_name: this.addExceptionsform.value.host_name,
                exception_comment: this.addExceptionsform.value.comment,
              };
              this.submited = false
              this.modalService.dismissAll({
                status: "created",
                data: data,
              });
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      

    } else {
      data = {
        // playbook_name: obj.playbook_name,
        ueba_id: this.addExceptionsform.value.ueba_id,
        exception_user_name: this.addExceptionsform.value.user_name,
        exception_host_name: this.addExceptionsform.value.host_name,
        tenant_id: this.addExceptionsform.value.tenant_id,
        created_by: str,
        created_at: date,
        time:this.addExceptionsform.value.time,
        exception_comment: this.addExceptionsform.value.comment,
      };
      // comment: this.addExceptionsform.value.comment,


      let data1 = {
        ueba_id: this.addExceptionsform.value.ueba_id,
        exception_user_name: this.addExceptionsform.value.user_name,
        exception_host_name: this.addExceptionsform.value.host_name,
        exception_comment: this.addExceptionsform.value.comment,
      };
      // comment: this.addExceptionsform.value.comment

      let data2 = {
        exception_user_name: "",
        exception_host_name: "",
        ueba_id:"",
        exception_comment: "",
      };
      // comment: "",

      if (JSON.stringify(data1) === JSON.stringify(data2)) {
        this.dataError = true
        return
      } else {
        this.dataError = false
      }
      // return
      // dataError
      this.settingsService.exceptionsCreation(data, tenantID).subscribe(
        (response: any) => {
          if (response.result == "created") {
            let data = {
              // playbook_name: obj.playbook_name,
              ueba_id: this.addExceptionsform.value.ueba_id,
              created_by: str,
              created_at: date,
              exception_user_name: this.addExceptionsform.value.user_name,
              exception_host_name: this.addExceptionsform.value.host_name,
              exception_comment: this.addExceptionsform.value.comment,
            };
            this.submited = false
            this.modalService.dismissAll({
              status: "created",
              data: data,
            });
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }
  clearForm() {
    this.modalService.dismissAll({
      status: "not-created",
    });
  }

  getLicenseDetailsList() {
    let data = {
      query: {
        match_all: {},
      },
    };
    this.settingsService.getLicenseDetailsList(data).subscribe(
      (response: any) => {
        let data = response.hits.hits;
        this.organizationList = [];
        data.forEach((item) => {
          let t = {
            start_date: item._source.start_date,
            end_date: item._source.end_date,
            id: item._id,
            tenant_id: item._source.tenant_id,
            organization_name: item._source.organization_name,
            index: item._index,
          };
          if (item._source.tenant_id)
            this.organizationList.push(t);
        });
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  playbookIdList() {
    let data = {
      match_all: {}
    };
    let tenantID = this.objArr[3];
    // let tenantID = "ab2";
    this.settingsService.scheduleList(data, tenantID).subscribe(
      (response: any) => {
        let data = response.hits.hits;
        this.playbookIdData = [];
        data.forEach((item) => {
          if (item._source.playbook_id != undefined) {
            let t = {
              id: item._id,
              index: item._index,
              playbook_id: item._source.playbook_id,
              playbook_name: item._source.playbook_name,
              status: item._source.status,
            };
            this.playbookIdData.push(t);
          }
        });
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getData() {
    this.tenantCatalogue = [];
    this.ruleStudioService.getCatalogueTenant().subscribe(
      (res: any) => {
        // console.log("res", res)
        // this.playbookIdData = res;
        res.forEach(data => {
          // console.log("data", data)
          let t = {
            playbook_id: data[0],
            playbook_name: data[1],
          };
          this.playbookIdData.push(t);

          // this.tenantCatalogue.push(data);
        });
        // console.log("this.playbookIdData", this.playbookIdData)
      },
      (error) => {
        console.log("error", error);
      }
    );

  }

}

