import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-investigation-view',
  templateUrl: './investigation-view.component.html',
  styleUrls: ['./investigation-view.component.scss']
})
export class InvestigationViewComponent implements OnInit {
  hunt_detection
  @Input() set DetectionId(val) {
    // console.log("analyticalData", val)
    this.hunt_detection = val
  }
  user_name
  dataTemp = [];
  datas: any;
  @Input() set value(val) {
    this.datas = val;
    this.model = val?.model;
    this.populateData();
  }
  @Input() set UserName(val) {
    this.user_name = val;
  }
  term: any;
  isLoader = true;
  titleArray: any;
  api: any;
  query: any;
  method: any;
  filteredData: any = [];
  results = []
  expectedValues: any
  type: any
  titleCount: any
  model = "Table";

  constructor(public dashboardService: DashboardService) { }


  ngOnInit(): void {

  }
  populateData() {

    this.titleArray = this.datas?.titleData;
    this.query = this.query;
    if (this.datas.api)
      this.dashboardService.dashboardApiCYBOT(this.replaceDataObjects(this.datas.query), this.datas.api, this.datas.method).subscribe(
        (result: any) => {
          if (result) {
            let data = result.hits.hits;
            let k = 0
            data.forEach(data => {
              let dataSub = []
              // dataSub.push(k + 1)
              this.datas.expectedValues.forEach(element => {
                let tempData = data?._source
                let expectedValuesArray = element.split('.')
                for (let i = 0; i < expectedValuesArray.length; i++) {
                  tempData = tempData[expectedValuesArray[i]]
                }
                let data1 = tempData
                dataSub.push(data1)
              });
              this.dataTemp.push(dataSub)
              k++
            });
            this.filteredData = this.dataTemp;
            // console.log(this.datas.api + ":", this.dataTemp)
            this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";
          }
          this.isLoader = false;
        },
        (inerror) => {
          console.log("error", inerror);
        })

  }

  search(a) {
    this.filteredData = [];
    if (this.dataTemp && this.term) {
      this.dataTemp.forEach(it => {
        let isExist = false;
        it.map(element => {
          if (element.toLowerCase().includes(this.term.toLowerCase())) {
            isExist = true;
          }
        });
        if (isExist) {
          this.filteredData.push(it);
        }
      })
    }
    else {
      this.filteredData = this.dataTemp;
    }
  }

  downloadCSV(data: any, heading: any) {
    data = [heading].concat(data)
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


  recurring(index, buckets, values) {
    buckets.forEach(b => {
      let temp = values
      if (b[index + 1]) {
        if (Array.isArray(b[index + 1].buckets) && b[index + 1].buckets.length > 0) {
          if (temp !== '') temp += ` ${b.key}`
          else temp += `${b.key}`
          this.recurring(index + 1, b[index + 1].buckets, temp)
        }
        else {
          let k = temp + ` ${b.key}`
          this.results.push(k.split(' '))
        }
      }
      else {
        let k = temp + ` ${b.key}`
        this.results.push(k.split(' '))
      }
      // console.log(values)
    })
  }
  filteredDataFn(filteredData, i) {
    if (filteredData[0]) {

      return filteredData[0][i]
    } else {
      return ''
    }
  }
  replaceDataObjects(data) {
    if (data && data.query && data.query.bool && data.query.bool.filter) {
      data.query.bool.filter.forEach(it => {
        if (it && it.match_phrase && it.match_phrase["hunt_detection_id"]) {
          it.match_phrase["hunt_detection_id"] = this.hunt_detection;
        }
      });
    }
    return data;
  }
}
