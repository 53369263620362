import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const apiUrl = environment.trApiUrl
const endPoint = environment.ogEndPoint

@Injectable({
  providedIn: 'root'
})
export class ThreatIntelligenceService {
  HttpOptions: any;
  constructor(private http: HttpClient, private router: Router) { }
  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    }
    else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message ? error.message : 'No error message'}`;
    }
    console.log(error);
    return throwError(errorMessage);
  }


  // vulnerability response

  getVulnerabilitiesData(){
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http.get<any>('https://analyticsapi.active-bytes.com/ti/vulndb/2.0', this.HttpOptions).pipe(catchError(this.handleError));
  }


  getVulnerabilitiesTotalData(){
    let authData: any = localStorage.getItem('access_token');
    if(authData){
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization" : authData,
          "Content-Type" : "application/json"
        })
      };
    }
    return this.http.get<any>('https://analyticsapi.active-bytes.com/ti/vulndb/2.0?pubStartDate=2023-01-01T00:00:00.000&pubEndDate=2023-04-30T00:00:00.000&noRejected&resultsPerPage=0', this.HttpOptions).pipe(catchError(this.handleError));
  }

  getSingleSeverityCounts(severity:string){
    let authData: any = localStorage.getItem('access_token');
    if(authData){
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization" : authData,
          "content-Type" : "application/json"
        })
      }
    }
    return this.http.get<any>(`https://analyticsapi.active-bytes.com/ti/vulndb/2.0?pubStartDate=2023-01-01T00:00:00.000&pubEndDate=2023-04-30T00:00:00.000&noRejected&resultsPerPage=0&cvssV3Severity=${severity}`, this.HttpOptions).pipe(catchError(this.handleError));
  }


  getCommunityFeedChart(data) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .post<object>(
        apiUrl + endPoint + "?pretty",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getAttributecategoryChart(data) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .post<object>(
        apiUrl + endPoint + "?pretty",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getViewDashboardData(data) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .post<object>(
        apiUrl + "/thp_enriched_observables*/_search?pretty",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getEventDetails(data, tenantID) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .post<object>(
        apiUrl + endPoint,
        // apiUrl + "/misp_event_data_" + tenantID + "/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


  getEventDescription(data, tenantID) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .post<object>(
        apiUrl + endPoint,
        // apiUrl + "/misp_event_data_" + tenantID + "/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getAttributeTypeTimeline(data, tenantID) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .post<object>(
        apiUrl + endPoint,
        // apiUrl + "/misp_event_data_" + tenantID + "/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getAttributeCategoryTimeline(data, tenantID) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .post<object>(
        apiUrl + endPoint,
        // apiUrl + "/misp_event_data_" + tenantID + "/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getFeedDistribution(data) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    data = JSON.stringify(data);
    return this.http
      .get<object>(apiUrl + endPoint,
        this.HttpOptions)
      .pipe(catchError(this.handleError));
  }

  getIOCCommunityfeeds(data) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .post<object>(
        apiUrl + endPoint,
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  addCloseCommentAdd(data, id, index) {
    data = JSON.stringify(data);
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": authorizationData,
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<object>(
      apiUrl + "/" + index + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

}
